import { toast } from "react-toastify";
import { http } from "../../../helpers";
import actionTypes from "../../action-types";
import i18n from "i18next";

export const AdminZonesAction = (
  pageNumber,
  name,
  email,
  domain,
  status,
  managerId
) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_ZONES_LOADING, payload: true });
    try {
      const params = {
        page: pageNumber ? pageNumber : 1,
        name,
        email,
        domain,
        status,
        managerId,
      };
      const response = await http.get("/zones", { params });
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_ADMIN_ZONES, payload: data });
      dispatch({ type: actionTypes.ADMIN_ZONES_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.ADMIN_ZONES_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminZoneSingleAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_SINGLE_ZONE_LOADING, payload: true });
    try {
      const response = await http.get(`/zones/${id}`);
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_ADMIN_SINGLE_ZONE, payload: data });
      dispatch({ type: actionTypes.ADMIN_SINGLE_ZONE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.ADMIN_SINGLE_ZONE_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminUpdateZoneAction = (id, zone) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_SINGLE_ZONE_LOADING, loading: true });
    try {
      await http.post(`/zones/${id}`, { ...zone });
      dispatch({ type: actionTypes.ADMIN_SINGLE_ZONE_LOADING, loading: false });
      toast.success(i18n.t("zone.zonesUpdated"));
    } catch (error) {
      dispatch({ type: actionTypes.ADMIN_SINGLE_ZONE_LOADING, loading: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};
