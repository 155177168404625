import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MultiChart from "../../../components/chart/MultiChart";
import DateFilter from "../../../components/filter/dateFilter";
import NoData from "../../../components/noData";
import echo from "../../../helpers/echo";
import { AdminZenAction, SetZenAction } from "../../../store/actions/admin/zen";
import styles from "./index.module.scss";

const AdminZen = ({ getZen, userId, darkMode, setZen, zen }) => {
  const { t } = useTranslation();

  useEffect(() => {
    echo(localStorage.getItem("dc-token"))
      .private(`Zen.Loaded.${userId}`)
      .listen("ZenLoaded", (data) => {
        setZen(data);
      });
    return () => {
      echo(localStorage.getItem("dc-token")).leave(`Zen.Loaded.${userId}`);
    };
  }, [setZen, userId]);

  const { dataChart: zenData, range, totalSumUsd } = zen;

  const { sumUsd, sumUsdWithoutCommission, categories, avg } = zenData;

  const chartZen = useMemo(
    () => ({
      series: [
        {
          name: t("zen.avg"),
          type: "column",
          data: avg,
          yaxis: {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#008FFB",
            },
            labels: {
              style: {
                colors: "#008FFB",
              },
              formatter: function (val) {
                return isFinite(val)
                  ? val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " %"
                  : val;
              },
            },
            title: {
              text: "",
              style: {
                color: "#008FFB",
              },
            },
            tooltip: {
              enabled: true,
            },
          },
        },
        {
          name: t("zen.sumUsdWithoutCommission"),
          type: "column",
          data: sumUsdWithoutCommission,
          yaxis: {
            seriesName: "Income",
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#00E396",
            },
            labels: {
              style: {
                colors: "#00E396",
              },
              formatter: function (val) {
                return isFinite(val)
                  ? val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : val;
              },
            },
            title: {
              text: "",
              style: {
                color: "#00E396",
              },
            },
          },
        },
        {
          name: t("zen.sumUsd"),
          type: "line",
          data: sumUsd,
          dataLabels: true,
          yaxis: {
            seriesName: "Revenue",
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#FEB019",
            },
            labels: {
              style: {
                colors: "#FEB019",
              },
              formatter: function (val) {
                return isFinite(val)
                  ? val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : val;
              },
            },
            title: {
              text: "",
              style: {
                color: "#FEB019",
              },
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2],
        formatter: function (val) {
          return isFinite(val)
            ? val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : val;
        },
        style: {
          fontSize: "18px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
        },
      },
    }),
    [sumUsd, sumUsdWithoutCommission, avg, t]
  );

  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t("menuSidebar.label.zen")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("menuSidebar.label.zen")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <DateFilter handleApply={getZen}>
          <span className={`btn-primary text-uppercase ${styles.zenTotal}`}>
            {t("common.total")}: {totalSumUsd}
          </span>
        </DateFilter>
        {!categories.length && <NoData />}
        {categories.length && (
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">
                    {t("zen.title")}{" "}
                    {range.start ? (
                      <span>{`${range.start} - ${range.end}`}</span>
                    ) : null}
                  </h3>
                  <div className="total"></div>
                </div>
                <div className="card-body">
                  {categories.length && (
                    <MultiChart
                      darkMode={darkMode}
                      series={chartZen.series}
                      categories={categories}
                      dataLabels={chartZen.dataLabels}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.currentUser.id,
    zen: state.adminZen.zen,
    darkMode: state.theme.darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getZen: (period) => dispatch(AdminZenAction(period)),
    setZen: (zen) => dispatch(SetZenAction(zen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminZen);
