import actionTypes from "../../../action-types";
const initialState = {
  isLoading: false,
  cssCode: "",
  jsCode: "",
  adfoxIds: "",
  enableCss: false,
  interval: 30,
  enableAdfoxRefresh: false,
};
const adminWebsiteScriptReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_ADMIN_WEBSITE_SCRIPT:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.ADMIN_WEBSITE_SCRIPT_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case actionTypes.ADMIN_WEBSITE_SCRIPT_ENABLE_CSS:
      return {
        ...state,
        enableCss: payload,
      };
    case actionTypes.ADMIN_WEBSITE_CHANGE_SCRIPT:
      return {
        ...state,
        jsCode: payload,
      };
    case actionTypes.ADMIN_WEBSITE_CHANGE_CSS:
      return {
        ...state,
        cssCode: payload,
      };
    case actionTypes.ADMIN_WEBSITE_SCRIPT_ADFOXIDS:
      return {
        ...state,
        adfoxIds: payload,
      };
    case actionTypes.ADMIN_WEBSITE_SCRIPT_ENABLE_AD_REFRESH:
      return {
        ...state,
        enableAdfoxRefresh: payload,
      };
    case actionTypes.ADMIN_WEBSITE_SCRIPT_AD_REFRESH_INTERVAL:
      return {
        ...state,
        interval: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default adminWebsiteScriptReducer;
