import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LineChart from "../../../components/chart/LineChart";
import DateFilter from "../../../components/filter/dateFilter";
import NoData from "../../../components/noData";
import { impressionFormat, showAmountOfNumber } from "../../../helpers";
import echo from "../../../helpers/echo";
import {
  AdminZenByZoneAction,
  AdminZenByZoneGetAction,
} from "../../../store/actions/admin/zen";

const AdminZenByZone = ({
  getZenByZone,
  userId,
  darkMode,
  zenByZones,
  loadZenByZone,
}) => {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(true);

  useEffect(() => {
    echo(localStorage.getItem("dc-token"))
      .private(`ZenByZones.Loaded.${userId}`)
      .listen("ZenByZonesLoaded", ({ data }) => {
        const { start, end } = data;
        getZenByZone(start, end);
      });
    return () => {
      echo(localStorage.getItem("dc-token")).leave(`Zen.Loaded.${userId}`);
    };
  }, [getZenByZone, userId]);

  const { zonesDataChart, range, totalZones } = zenByZones;

  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t("menuSidebar.label.zenByZones")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("menuSidebar.label.zen")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <DateFilter handleApply={loadZenByZone} />
        {!zonesDataChart.categories.length && <NoData />}
        {zonesDataChart.categories.length && (
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <h3 className="card-title">
                      {t("menuSidebar.label.zenByZones")}{" "}
                      {range.start ? (
                        <span>{`${range.start} - ${range.end}`}</span>
                      ) : null}
                    </h3>
                    <div className="collapse-icon-background">
                      <i
                        role="button"
                        className={
                          collapse ? "fas fa-arrow-up" : "fas fa-arrow-down"
                        }
                        onClick={() => setCollapse(!collapse)}
                      ></i>
                    </div>
                  </div>
                  <div className="total"></div>
                </div>
                {collapse && (
                  <div className="card-body">
                    <LineChart
                      series={zonesDataChart.series}
                      categories={zonesDataChart.categories}
                      darkMode={darkMode}
                    />
                  </div>
                )}
              </div>
            </div>
            {totalZones.length && (
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      {t("menuSidebar.label.zones")}
                    </h3>
                  </div>
                  <div className="card-body table-responsive p-0 ">
                    <table className="table table-striped table-valign-middle">
                      <thead>
                        <tr>
                          <th>{t("zone.size")}</th>
                          <th>{t("zone.profitability")}</th>
                          <th>{t("zone.impressions")}</th>
                          <th>{t("zone.revenue")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {totalZones
                          .sort(
                            (a, b) =>
                              b.sum / b.impressions - a.sum / a.impressions
                          )
                          .map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.size}</td>
                                <td>
                                  {showAmountOfNumber(
                                    (item.sum / item.impressions) * 100
                                  )}
                                  {" %"}
                                </td>
                                <td>{impressionFormat(item.impressions)}</td>
                                <td>{showAmountOfNumber(item.sum)}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.currentUser.id,
    zenByZones: state.adminZen.zenByZones,
    darkMode: state.theme.darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadZenByZone: (period) => dispatch(AdminZenByZoneAction(period)),
    getZenByZone: (startDate, endDate) =>
      dispatch(AdminZenByZoneGetAction(startDate, endDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminZenByZone);
