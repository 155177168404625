import actionTypes from "../../../action-types";
const initialState = {
  isLoading: false,
  list: [],
};
const adminScriptsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_ADMIN_SCRIPTS:
      return {
        ...state,
        list: payload,
      };
    case actionTypes.ADMIN_SCRIPTS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default adminScriptsReducer;
