import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LineChart from "../../../../../components/chart/LineChart";
import DateFilter from "../../../../../components/filter/dateFilter";
import PageLoading from "../../../../../components/page-loading/PageLoading";
import { showAmountOfNumber } from "../../../../../helpers";
import { ZonesReports } from "../../../../../store/actions/reports";
const ZonesReport = ({
  match,
  isLoading,
  website,
  chart,
  total,
  list,
  zonesReports,
  initialPeriod,
  darkMode,
}) => {
  const { id } = match.params;
  const [t] = useTranslation();
  const [period, setPeriod] = useState(initialPeriod);
  const [collapse, setCollapse] = useState(true);

  useEffect(() => {
    //if (!website.id || website.id !== Number(id) || !total)
    zonesReports(id, period);
  }, [zonesReports, id, period]);

  const zonesList = list.map((item) => {
    return (
      <tr key={item.zone}>
        <td>{item.title}</td>
        <td>{item.size}</td>
        <td>{showAmountOfNumber((item.sum / total) * 100)} %</td>
        <td className="text-bold">{showAmountOfNumber(item.sum)}</td>
      </tr>
    );
  });

  const zonesListByDayHead = chart.series.map((item) => {
    return <th key={item.zone}>{item.name}</th>;
  });

  const zonesListByDayBody = Array.from(chart.categories).map(
    (date, parentIndex) => {
      return (
        <tr key={parentIndex}>
          <td>{date}</td>
          {chart.series.map((item, index) => {
            return (
              <td key={index}>{showAmountOfNumber(item.data[parentIndex])}</td>
            );
          })}
          <td className="text-bold">
            {showAmountOfNumber(chart.sumDays[parentIndex])} $
          </td>
        </tr>
      );
    }
  );

  const zonesListByDayFoot = chart.series.map((zone, index) => {
    return (
      <th key={index}>
        {showAmountOfNumber(zone.data.reduce((prev, curr) => prev + curr, 0))} $
      </th>
    );
  });

  let template = isLoading ? (
    <PageLoading />
  ) : (
    <>
      <div className="row">
        <div className="col-xl-12 col-sm-12 col-12 mt-4">
          <div className="card">
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>{t("sources.title")}</th>
                    <th>{t("sources.type")}</th>
                    <th>{t("zones.efficiency")}</th>
                    <th>{t("sources.profit")}</th>
                  </tr>
                </thead>
                <tbody>{zonesList}</tbody>
                <tfoot>
                  <tr>
                    <th>{t("common.total")}</th>
                    <th></th>
                    <th></th>
                    <th>{showAmountOfNumber(total)}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-12">
          <div className={`card`}>
            <div className="card-header">
              <div className="d-flex justify-content-between">
                <h3 className="card-title">{t("dashboard.website.stat")}</h3>
                <div className="collapse-icon-background">
                  <i
                    role="button"
                    className={
                      collapse ? "fas fa-arrow-up" : "fas fa-arrow-down"
                    }
                    onClick={() => setCollapse(!collapse)}
                  ></i>
                </div>
              </div>
            </div>
            {collapse && (
              <div className="card-body">
                <LineChart
                  darkMode={darkMode}
                  isLoading={isLoading}
                  series={chart.series}
                  categories={chart.categories}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-xl-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-body table-responsive p-0">
              <table className="table table-striped table-valign-middle text-center">
                <thead>
                  <tr>
                    <th>{t("common.date")}</th>
                    {zonesListByDayHead}
                    <th>{t("common.total")}</th>
                  </tr>
                </thead>
                <tbody>{zonesListByDayBody}</tbody>
                <tfoot>
                  <tr>
                    <th>{t("common.total")}</th>
                    {zonesListByDayFoot}
                    <th>{showAmountOfNumber(total)}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">{<h1>{t("zones.title")}</h1>}</div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/user/websites/reports">
                      {t("menuSidebar.label.reports")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/user/websites/${id}/reports`}>
                      {website.cname}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">{t("zones.title")}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <DateFilter handleApply={setPeriod} period={period}>
          <div className="ml-auto list-unstyled">
            <Link
              to={`/user/websites/${id}/zones/add`}
              className="btn btn-primary"
            >
              <i className="fa fa-plus-circle mr-2"></i>
              {t("zone.addZones")}
            </Link>
          </div>
        </DateFilter>
        {template}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { chart, total, list, website, filterShow, isLoading } =
    state.zonesReports;
  return {
    chart,
    total,
    list,
    website,
    filterShow,
    isLoading,
    darkMode: state.theme.darkMode,
    initialPeriod: state.websiteReport.period,
  };
};

const mapDispatchToProps = (dispatch) => ({
  zonesReports: (id, period) => dispatch(ZonesReports(id, period)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ZonesReport);
