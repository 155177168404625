import React, { useEffect } from "react";
import { connect } from "react-redux";
import LineChart from "../components/chart/LineChart";
import SparkLineChart from "../components/chart/SparkLine";
import RadialBar from "../components/chart/RadialBar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WebsiteReportAction } from "../store/actions/reports";
import PageLoading from "../components/page-loading/PageLoading";

const Dashboard = ({ user, reports, reportLoad, isLoading, darkMode }) => {
  useEffect(() => {
    if (reports.total === 0) reportLoad();
  }, [reports, reportLoad]);
  const yesterday = reports.yesterday;
  const websitesList = reports.chartMultisite.series
    .sort(
      (a, b) =>
        parseFloat(b.sum_usd.replace(",", "")) -
        parseFloat(a.sum_usd.replace(",", ""))
    )
    .map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <Link to={`/user/websites/${item.id}/reports`}>{item.name}</Link>
          </td>
          {user.userBonus ? (
            <td>{item.sum_usd_total}</td>
          ) : (
            <td>{item.sum_usd}</td>
          )}
          {user.userBonus && <td className="text-bonus">{item.sum_bonus}</td>}
          <td>{item.impressions_usd}</td>
          <td>{item.cpm_usd}</td>
          <td>
            <Link
              to={`/user/websites/${item.id}/reports`}
              className="text-muted"
            >
              <i className="fas fa-chart-line"></i>
            </Link>
          </td>
        </tr>
      );
    });

  const [t] = useTranslation();

  let template = isLoading ? (
    <PageLoading />
  ) : (
    <>
      <div className="row mt-4 mb-4">
        <RadialBar
          darkMode={darkMode}
          series={[yesterday.sum, yesterday.impressions, yesterday.cpm]}
          title={t("dashboard.chart.yesterday")}
          colors={["#f2712a99", "#98e4c8", "#98a1e4"]}
          labels={[
            t("dashboard.website.profit"),
            t("dashboard.website.impressions"),
            t("dashboard.website.cpm"),
          ]}
        />
        <SparkLineChart
          darkMode={darkMode}
          colors={["#f2712a"]}
          total={`$ ${reports.totalSumWithBonus}`}
          subtitle={`${t("dashboard.website.profit")} ${t(
            t("dashboard.website.forCurrentMonth")
          )}`}
          sparklineData={reports.sparkLineSum.data}
          labels={reports.sparkLineSum.labels}
        />
        <SparkLineChart
          darkMode={darkMode}
          colors={["#98e4c8"]}
          total={`👁 ${reports.totalImps}`}
          subtitle={`${t("dashboard.website.impressions")} ${t(
            t("dashboard.website.forCurrentMonth")
          )}`}
          sparklineData={reports.sparkLineImps.data}
          labels={reports.sparkLineImps.labels}
        />
        <SparkLineChart
          darkMode={darkMode}
          colors={["#98a1e4"]}
          total={`$ ${reports.totalCpm}`}
          subtitle={`${t("dashboard.website.cpm")} ${t(
            t("dashboard.website.forCurrentMonth")
          )}`}
          sparklineData={reports.sparkLineCpm.data}
          labels={reports.sparkLineImps.labels}
        />
      </div>
      <div className="row ">
        <div className="col-lg-6 col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{t("dashboard.website.list")}</h3>
            </div>
            <div className="card-body table-responsive p-0 ">
              <table className="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>{t("dashboard.website.name")}</th>
                    <th>{t("dashboard.website.profit")}</th>
                    {user.userBonus && (
                      <th className="text-bonus">
                        {t("dashboard.website.bonus")}
                      </th>
                    )}
                    <th>{t("dashboard.website.impressions")}</th>
                    <th>{t("dashboard.website.cpm")}</th>
                    <th>{t("dashboard.website.more")}</th>
                  </tr>
                </thead>
                <tbody>{websitesList}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className={`card`}>
            <div className="card-header">
              <h3 className="card-title">{t("dashboard.website.stat")}</h3>
            </div>
            <div className="card-body">
              <LineChart
                darkMode={darkMode}
                series={reports.chartMultisite.series}
                categories={reports.chartMultisite.categories}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return <div className="container-fluid">{template}</div>;
};

const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
  reports: state.reports.reports,
  isLoading: state.reports.isLoading,
  darkMode: state.theme.darkMode,
});

const mapDispatchToProps = (dispatch) => ({
  reportLoad: () => dispatch(WebsiteReportAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
