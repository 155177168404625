export const accounts = [
  {
    id: 8,
    name: "Digital Caramel 2",
    script: ` `,
  },
  {
    id: 38,
    name: "Monetize",
    script: ` `,
  },
];
