import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppButton from "../../../components/button/Button";
import DateFilter from "../../../components/filter/dateFilter";
import echo from "../../../helpers/echo";
import {
  ExportXlsxAction,
  SetExportLink,
} from "../../../store/actions/admin/export";

const Export = ({ exportXlsx, userId, setExportLink }) => {
  const { t } = useTranslation();
  const [exportType, setExportType] = useState("agency");
  const [period, setPeriod] = useState("month");

  useEffect(() => {
    echo(localStorage.getItem("dc-token"))
      .private(`Export.Loaded.${userId}`)
      .listen("ExportXlsxEvent", (data) => {
        setExportLink(data);
      });
    return () => {
      echo(localStorage.getItem("dc-token")).leave(`Zen.Loaded.${userId}`);
    };
  }, [userId, setExportLink]);

  const handleExport = () => {
    if (exportType && period) {
      exportXlsx(exportType, period);
    }
  };

  return (
    <div className="container-fluid">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t("menuSidebar.label.export")}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/admin">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  {t("menuSidebar.label.export")}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <DateFilter handleApply={setPeriod} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <form className="card-body">
              <div className="form-group my-4 ml-4">
                <div className="form-check">
                  <input
                    id="agency"
                    type="radio"
                    name="export"
                    className="form-check-input"
                    value={exportType}
                    checked={exportType === "agency"}
                    onChange={() => setExportType("agency")}
                  />
                  <label htmlFor="agency" className="form-check-label">
                    {t("export.label.agency")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    id="user"
                    type="radio"
                    name="export"
                    className="form-check-input"
                    value={exportType}
                    checked={exportType === "user"}
                    onChange={() => setExportType("user")}
                  />
                  <label htmlFor="user" className="form-check-label">
                    {t("export.label.user")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    id="referral"
                    type="radio"
                    name="export"
                    className="form-check-input"
                    value={exportType}
                    checked={exportType === "referral"}
                    onChange={() => setExportType("referral")}
                  />
                  <label htmlFor="referral" className="form-check-label">
                    {t("export.label.referral")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    id="sources"
                    type="radio"
                    name="export"
                    className="form-check-input"
                    value={exportType}
                    checked={exportType === "sources"}
                    onChange={() => setExportType("sources")}
                  />
                  <label htmlFor="sources" className="form-check-label">
                    {t("menuSidebar.label.sources")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    id="zones"
                    type="radio"
                    name="export"
                    className="form-check-input"
                    value={exportType}
                    checked={exportType === "zones"}
                    onChange={() => setExportType("zones")}
                  />
                  <label htmlFor="zones" className="form-check-label">
                    {t("export.label.zones")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    id="activeUsers"
                    type="radio"
                    name="export"
                    className="form-check-input"
                    value={exportType}
                    checked={exportType === "activeUsers"}
                    onChange={() => setExportType("activeUsers")}
                  />
                  <label htmlFor="activeUsers" className="form-check-label">
                    {t("users.single.activeUsers")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    id="pendingZones"
                    type="radio"
                    name="export"
                    className="form-check-input"
                    value={exportType}
                    checked={exportType === "pendingZones"}
                    onChange={() => setExportType("pendingZones")}
                  />
                  <label htmlFor="pendingZones" className="form-check-label">
                    {t("users.single.pendingZones")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    id="userAnswers"
                    type="radio"
                    name="export"
                    className="form-check-input"
                    value={exportType}
                    checked={exportType === "userAnswers"}
                    onChange={() => setExportType("userAnswers")}
                  />
                  <label htmlFor="userAnswers" className="form-check-label">
                    {t("users.single.userAnswers")}
                  </label>
                </div>
                <AppButton
                  onClick={handleExport}
                  icon="export"
                  className="my-2"
                >
                  {t("export.label.export")}
                </AppButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.currentUser.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    exportXlsx: (exportType, date) => {
      dispatch(ExportXlsxAction(exportType, date));
    },
    setExportLink: (payload) => {
      dispatch(SetExportLink(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Export);
