export const status = {
  approved: {
    icon: "check-circle",
    color: "#82c91e",
    text: "website.approved",
  },
  pending: {
    icon: "history",
    color: "#ff9800",
    text: "website.pending",
  },
  canceled: {
    icon: "ban",
    color: "red",
    text: "website.canceled",
  },
};
export const zoneSize = [
  { id: 1, size_id: "1x1", name: "Custom size" },
  { id: 2, size_id: "300x250", name: "Desktop 300x250" },
  { id: 3, size_id: "300x300", name: "Desktop 300x300" },
  { id: 4, size_id: "336x280", name: "Desktop 336x280" },
  { id: 5, size_id: "240x400", name: "Desktop 240x400" },
  { id: 6, size_id: "160x600", name: "Desktop 160x600" },
  { id: 7, size_id: "300x600", name: "Desktop 300x600" },
  { id: 8, size_id: "728x90", name: "Desktop 728x90" },
  { id: 9, size_id: "970x90", name: "Desktop 970x90" },
  { id: 10, size_id: "970x250", name: "Desktop 970x250" },
  { id: 11, size_id: "320x50", name: "Mobile 320x50" },
  { id: 12, size_id: "320x100", name: "Mobile 320x100" },
  { id: 13, size_id: "300x250", name: "Mobile 300x250" },
  { id: 14, size_id: "336x280", name: "Mobile 336x280" },
  // { id: 15, size_id: "1x1", name: "Video Outstream" },
  // { id: 16, size_id: "1x1", name: "Video Instream" },
  { id: 18, size_id: "1x1", name: "Fullscreen Desktop" },
  { id: 24, size_id: "1x1", name: "Fullscreen Mobile" },
  { id: 19, size_id: "1x1", name: "Catfish Mobile" },
  { id: 20, size_id: "1x1", name: "Catfish Desktop" },
  // { id: 22, size_id: "1x1", name: "Interscroller Mobile" },
  // { id: 26, size_id: "1x1", name: "Turbo" },
  // { id: 27, size_id: "1x1", name: "Lenta" },
];
export const filterList = [
  {
    value: "yesterday",
    text: "ranges.yesterday",
  },
  {
    value: "7days",
    text: "ranges.7days",
  },
  {
    value: "30days",
    text: "ranges.30days",
  },
  {
    value: "month",
    text: "ranges.month",
  },
  {
    value: "lastmonth",
    text: "ranges.lastmonth",
  },
];

export const getHumanPeriod = (period) => {
  const humanPeriod = filterList.find((item) => item.value === period);
  return humanPeriod ? humanPeriod.text : period;
};
