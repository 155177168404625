import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppButton from "../../../../components/button/Button";
import MatchingModal from "../../../../components/modals/matchingModal";
import {
  GetWebsiteByIDAction,
  UpdateZoneMatchAction,
  UpdateZonesDetailsActionCreator,
} from "../../../../store/actions/websites";

const ZonesMatching = ({
  match,
  website,
  getWebsiteWithZones,
  updateZoneMatch,
  updateZonesDetailsAction,
}) => {
  const { id } = match.params;
  const { t } = useTranslation();
  const { zones } = website;
  const [showMatchModal, setShowMatchModal] = useState(null);
  useEffect(() => {
    getWebsiteWithZones(id);
  }, [id, getWebsiteWithZones]);
  return (
    <div className="container-fluid">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              {
                <h1>
                  {t("zone.matching")}{" "}
                  <Link
                    to={`/admin/websites/${website.id}`}
                    className="text-success"
                  >
                    [{website.url}]
                  </Link>
                </h1>
              }
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/websites">
                    {t("menuSidebar.label.websites")}
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/admin/websites/${website.id}`}>
                    {website.url}
                  </Link>
                </li>
                <li className="breadcrumb-item active">{t("zone.matching")}</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="card rounded">
        <div className="card-body">
          {zones.map((zone, i) => {
            return (
              <div className="row" key={zone.id}>
                <div className="form-group col-md-1">
                  <div>
                    <strong>{t("zone.name")}</strong>
                  </div>
                  <div>{zone.name}</div>
                </div>
                <div className="form-group col-md-2">
                  <div>
                    <strong> {t("zone.size")}</strong>
                  </div>
                  <div>{zone.size}</div>
                </div>
                <div className="form-group col-md-1">
                  <div>
                    <strong> {t("zone.status")}</strong>
                  </div>
                  <div>{zone.status}</div>
                </div>
                <div className="form-group col-md-4">
                  <div>
                    <strong> {t("zone.script")}</strong>
                  </div>
                  {/* <textarea
                    className="w-100"
                    rows={8}
                    cols={10}
                    defaultValue={zone.script}
                  ></textarea> */}
                  <code>
                    <pre>{zone.script}</pre>
                  </code>
                </div>
                <div className="form-group col-md-4 text-center">
                  <div>
                    <strong> {t("zone.matching")}</strong>
                  </div>
                  <div>
                    <AppButton
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setShowMatchModal(zone.id)}
                    >
                      {t("zone.matching")}
                    </AppButton>
                  </div>
                </div>
                {showMatchModal === zone.id && (
                  <MatchingModal
                    id={zone.id}
                    details={zone.details}
                    show={showMatchModal === zone.id}
                    title={zone.name}
                    onHide={() => setShowMatchModal(null)}
                    updateZoneMatch={updateZoneMatch}
                    updateZonesDetails={updateZonesDetailsAction}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWebsiteWithZones: (id) => dispatch(GetWebsiteByIDAction(id)),
    updateZoneMatch: (id, data) => dispatch(UpdateZoneMatchAction(id, data)),
    updateZonesDetailsAction: (id, sources) =>
      dispatch(UpdateZonesDetailsActionCreator({ id, sources })),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(ZonesMatching));
