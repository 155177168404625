import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import Cookies from "js-cookie";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./index.module.css";

import {
  SetWebsiteReportPeriod,
  WebsiteReport,
} from "../../../../store/actions/reports";
import LineChart from "../../../../components/chart/LineChart";
import PageLoading from "../../../../components/page-loading/PageLoading";
import StatCard from "../../../../components/card/StatCard";
import MultiChart from "../../../../components/chart/MultiChart";
import Image from "../../../../components/image";
import Filter from "../../../../components/filter";
import AppButton from "../../../../components/button/Button";

import { impressionFormat, showAmountOfNumber } from "../../../../helpers";
import { getHumanPeriod, status } from "../../../../helpers/websites";

import { ExportWebsiteXlsxAction } from "../../../../store/actions/admin/export";

const SingleReport = ({
  user,
  match,
  isLoading,
  reports,
  period,
  website,
  setPeriod,
  websiteReport,
  darkMode,
  exportWebsiteXlsx,
}) => {
  const { id } = match.params;
  const [t] = useTranslation();
  const [isNoted, setIsNoted] = useState();
  useEffect(() => {
    websiteReport(id, period);
  }, [websiteReport, id, period]);

  useEffect(() => {
    setIsNoted(Cookies.get("isUserNotedAboutPayment"));
  }, []);

  const [collapse, setCollapse] = useState({
    statistic: true,
    sourceStatistic: true,
  });

  const handleNoteClose = () => {
    Cookies.set("isUserNotedAboutPayment", true, { expires: 30 });
    setIsNoted(Cookies.get("isUserNotedAboutPayment"));
  };

  const handleApply = useCallback(
    (_, picker) => {
      const pickerDate = `${picker.startDate.format(
        "YYYY-MM-DD"
      )} - ${picker.endDate.format("YYYY-MM-DD")}`;
      setPeriod(pickerDate);
    },
    [setPeriod]
  );
  const handleExport = () => {
    if (period) {
      exportWebsiteXlsx(id, period);
    }
  };
  const {
    chartMultiParams,
    chartMultiSources,
    sourceData,
    impressions,
    sum,
    cpm,
    sumBonus,
  } = reports;
  const chartCIR = useMemo(
    () => ({
      series: [
        {
          name: t("dashboard.website.impressions"),
          type: "column",
          data: chartMultiParams.impsDays,
        },
        {
          name: t("dashboard.website.cpm"),
          type: "column",
          data: chartMultiParams.cpmDays,
        },
        {
          name: t("dashboard.website.profit"),
          type: "line",
          data: chartMultiParams.sumDays,
        },
      ],
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2],
        formatter: function (val) {
          return isFinite(val)
            ? val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : val;
        },
        style: {
          fontSize: "18px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
        },
      },
    }),
    [chartMultiParams, t]
  );
  const sourcesList = sourceData
    .sort((a, b) => b.sum - a.sum)
    .map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <Image
              className={styles["source-logo"]}
              name={`${item.type}-logo.png`}
            />
          </td>
          <td>{item.title}</td>
          <td>{impressionFormat(item.impression)}</td>
          <td>{item.cpm}</td>
          {user.userBonus && <td className="text-bonus">{item.sum_bonus}</td>}
          {item.sum_earnings ? (
            <td className="text-bold">{item.sum_earnings}</td>
          ) : (
            <td className="text-bold">{item.sum}</td>
          )}
        </tr>
      );
    });

  const sourceListByDayHead = chartMultiSources.series.map((source, index) => {
    return (
      <th key={index}>
        <Image
          className={`${styles["source-logo"]} d-block`}
          name={`${source.source}-logo.png`}
        />
        <span>{source.name}</span>
      </th>
    );
  });

  const sourceListByDayBody = Array.from(chartMultiSources.categories).map(
    (date, parentIndex) => {
      return (
        <tr key={parentIndex}>
          <td>{date}</td>
          {chartMultiSources.series.map((item, index) => {
            return (
              <td key={index}>{showAmountOfNumber(item.data[parentIndex])}</td>
            );
          })}
          {user.userBonus && (
            <td className="text-bonus">
              {showAmountOfNumber(chartMultiParams.bonusDays[date])}
            </td>
          )}
          {user.userBonus ? (
            <td className="text-bold">
              {showAmountOfNumber(
                chartMultiParams.bonusDays[date] +
                  chartMultiParams.sumDays[parentIndex]
              )}{" "}
              $
            </td>
          ) : (
            <td className="text-bold">
              {showAmountOfNumber(chartMultiParams.sumDays[parentIndex])} $
            </td>
          )}
        </tr>
      );
    }
  );

  const sourceListByDayFoot = chartMultiSources.series.map((source, index) => {
    return (
      <th key={index}>
        {showAmountOfNumber(source.data.reduce((prev, curr) => prev + curr, 0))}{" "}
        $
      </th>
    );
  });

  const toggleCollapse = (key) => {
    setCollapse((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const humanPeriod = useMemo(() => getHumanPeriod(period), [period]);

  let template = isLoading ? (
    <PageLoading />
  ) : (
    <>
      <div className="row">
        <div className="col-xl-3 col-sm-6 col-12">
          <StatCard
            title={t("dashboard.website.name")}
            color="#5172b3"
            icon="globe"
            footerIcon="check-circle"
            footerIconColor={status[website?.status]?.color}
            total={
              <a rel="noreferrer" target="_blank" href={`//${website?.url}`}>
                {website?.cname}
              </a>
            }
            period={t(status[website?.status]?.text)}
          />
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <StatCard
            title={t("dashboard.website.profit")}
            color="#FFA87D"
            icon="wallet"
            total={sum}
            period={t(humanPeriod)}
          />
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <StatCard
            title={t("dashboard.website.impressions")}
            color="#98e4c8"
            icon="eye"
            total={impressionFormat(impressions)}
            period={t(humanPeriod)}
          />
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <StatCard
            title={t("dashboard.website.cpm")}
            color="#98a1e4"
            icon="coins"
            total={cpm}
            period={t(humanPeriod)}
          />
        </div>
        {website?.config?.dc_website_id && (
          <div className="col-lg-12 col-12">
            <div className="card">
              <a
                href={`${process.env.REACT_APP_CLIENT_URL}/user/websites/${website.config.dc_website_id}/reports`}
                target="_blank"
                rel="noreferrer"
              >
                <p className="card-title mx-4 my-4">
                  <i className="fas fa-link mr-2"></i>
                  {t("website.linkToDashboard")}
                </p>
              </a>
            </div>
          </div>
        )}
        {!!chartMultiSources.series.length && (
          <>
            <div className="col-lg-12 col-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <h3 className="card-title">
                      {t("dashboard.website.stat")}
                    </h3>
                    <div className="collapse-icon-background">
                      <i
                        role="button"
                        className={
                          collapse.statistic
                            ? "fas fa-arrow-up"
                            : "fas fa-arrow-down"
                        }
                        onClick={() => toggleCollapse("statistic")}
                      ></i>
                    </div>
                  </div>
                </div>
                {collapse.statistic && (
                  <div className="card-body">
                    <MultiChart
                      darkMode={darkMode}
                      series={chartCIR.series}
                      categories={chartMultiSources.categories}
                      dataLabels={chartCIR.dataLabels}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-12 col-sm-12 col-12">
              <div className="card">
                <div className="card-body table-responsive p-0">
                  <table className="table table-striped table-valign-middle table-sm">
                    <thead>
                      <tr>
                        <th>{t("sources.type")}</th>
                        <th>{t("sources.title")}</th>
                        <th>{t("sources.impressions")}</th>
                        <th>{t("sources.cpm")}</th>
                        {user.userBonus && (
                          <th className="text-bonus">{t("sources.bonus")}</th>
                        )}
                        <th>{t("sources.profit")}</th>
                      </tr>
                    </thead>
                    <tbody>{sourcesList}</tbody>
                    <tfoot>
                      <tr>
                        <th>{t("common.total")} (USD)</th>
                        <th></th>
                        <th>{impressionFormat(impressions)}</th>
                        <th>{cpm}</th>
                        {user.userBonus && (
                          <th className="text-bonus">{sumBonus}</th>
                        )}
                        <th>{sum}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <AppButton
                type="button"
                className="btn btn-primary mb-3"
                onClick={handleExport}
              >
                {t("export.label.export")}
              </AppButton>
            </div>
            <div className="col-lg-12 col-12">
              <div className={`card`}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <h3 className="card-title">
                      {t("dashboard.website.stat")}
                    </h3>
                    <div className="collapse-icon-background">
                      <i
                        role="button"
                        className={
                          collapse.sourceStatistic
                            ? "fas fa-arrow-up"
                            : "fas fa-arrow-down"
                        }
                        onClick={() => toggleCollapse("sourceStatistic")}
                      ></i>
                    </div>
                  </div>
                </div>
                {collapse.sourceStatistic && (
                  <div className="card-body">
                    <LineChart
                      darkMode={darkMode}
                      isLoading={isLoading}
                      series={chartMultiSources.series}
                      categories={chartMultiSources.categories}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-12 col-sm-12 col-12">
              <div className="card">
                <div className="card-body table-responsive p-0">
                  <table className="table table-striped table-valign-middle table-sm">
                    <thead>
                      <tr>
                        <th>{t("common.date")}</th>
                        {sourceListByDayHead}
                        {user.userBonus && (
                          <th className="text-bonus">
                            {t("dashboard.website.bonus")}
                          </th>
                        )}
                        <th>{t("common.total")}</th>
                      </tr>
                    </thead>
                    <tbody>{sourceListByDayBody}</tbody>
                    <tfoot>
                      <tr>
                        <th>{t("common.total")} </th>
                        {sourceListByDayFoot}
                        {user.userBonus && (
                          <th className="text-bonus">{sumBonus} ₽</th>
                        )}
                        <th>{sum} ₽</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-12">
              <div className="mb-3">
                <Link to="/user/websites/new" className="btn btn-primary">
                  <i className="fa fa-plus-circle mr-2"></i>
                  {t("websites.button.add")}
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1>{t('dashboard.website.list')}</h1> */}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/user/websites/reports">
                      {t("menuSidebar.label.reports")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">{website?.cname}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <div className={`mx-auto col-sm-12 ${isNoted ? "d-none" : ""}`}>
          <div
            className="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            <span>
              <strong> Note: </strong> If a publisher's account is found to be
              in violation of{" "}
              <a
                href="https://policies.google.com/terms"
                style={{ color: "#343a40" }}
              >
                Terms of Service
              </a>{" "}
              or
              <a
                href="https://support.google.com/admanager/answer/9059370?visit_id=638445427794099482-346699176&rd=1"
                style={{ color: "#343a40" }}
              >
                {" "}
                policies
              </a>
              <span>
                , we may, at the period of payments, withhold payment.
              </span>
            </span>

            <button
              type="button"
              className="close"
              style={{ color: "#000", opacity: 0.6 }}
              onClick={handleNoteClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>

        <Filter
          period={period}
          setPeriod={setPeriod}
          handleApply={handleApply}
          isLoading={isLoading}
        >
          <ul className="ml-auto list-unstyled">
            <li>
              <Link
                to={`/user/websites/${id}/zones/reports`}
                className="btn btn-info"
              >
                <i className="fas fa-ad mr-2"></i>
                {t("zones.title")}
              </Link>
            </li>
          </ul>
        </Filter>
        {template}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.currentUser,
    website: state.websiteReport.website,
    reports: state.websiteReport.reports,
    period: state.websiteReport.period,
    isLoading: state.websiteReport.isLoading,
    darkMode: state.theme.darkMode,
    width: state.theme.width,
  };
};
const mapDispatchToProps = (dispatch) => ({
  websiteReport: (id, period) => dispatch(WebsiteReport(id, period)),
  setPeriod: (period) => dispatch(SetWebsiteReportPeriod(period)),
  exportWebsiteXlsx: (id, date) => {
    dispatch(ExportWebsiteXlsxAction(id, date));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(SingleReport));
