import React, { memo, useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AppButton from "../button/Button";
import {
  firstCharToUpperCaseAndReplaceUnderscore,
  matchSources,
} from "../../helpers";

const MatchingModal = ({
  id,
  show = false,
  title,
  details,
  setDetails,
  onHide,
  updateZoneMatch,
  updateZonesDetails,
}) => {
  const { t } = useTranslation();
  const [zoneDetails, setZoneDetails] = useState(null);
  const handleZoneDetails = useCallback(
    (details) => {
      setZoneDetails({
        ...details,
      });
      updateZonesDetails(id, details);
    },
    [id, updateZonesDetails]
  );
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <button type="button" className="close" onClick={onHide}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body id="zone-script">
        <div className="container">
          <div className="row">
            {matchSources.map((source, i) => {
              return (
                <div className="form-group col-md-6" key={i}>
                  <label>
                    {firstCharToUpperCaseAndReplaceUnderscore(source)}
                  </label>
                  <input
                    name={source}
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      const matchSources = { ...details };
                      matchSources[name] = value;
                      handleZoneDetails({
                        ...matchSources,
                      });
                    }}
                    defaultValue={details?.[source]}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AppButton onClick={() => updateZoneMatch(id, zoneDetails)}>
          {t("zone.matching")}
        </AppButton>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(MatchingModal);
