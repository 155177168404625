import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BillingTab from "../../components/tabs/BillingTab";
import { connect } from "react-redux";
import {
  CheckAndUpdateUser,
  SendCodeToMail,
  UserPasswordUpdateAction,
  UserUpdateAction,
} from "../../store/actions/users";
import PrimaryTab from "../../components/tabs/PrimaryTab";
import PasswordChangeTab from "../../components/tabs/PasswordChangeTab";
import CodeCheck from "../../components/modals/codeCheck";
import ManagerLinksTab from "../../components/tabs/ManagerLinksTab";

const Profile = ({
  user,
  isLoading,
  updateUser,
  updatePassword,
  match,
  sendCodeToMail,
  checkAndUpdate,
  darkMode,
}) => {
  const [activeTab, setActiveTab] = useState("BILLING");
  const [t] = useTranslation();

  const [codeCheck, setCodeCheck] = useState({
    show: false,
    title: null,
    onHide: null,
    onSubmit: null,
    resendCode: null,
    theme: "",
  });

  const handleCloseCodeCheck = () => {
    setCodeCheck({
      title: "",
      show: false,
      onSubmit: () => {},
      resendCode: () => {},
      onHide: () => {},
    });
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const updateUserWithCode = (userId, values) => {
    sendCodeToMail();
    setCodeCheck({
      title: t("users.single.mailSended"),
      show: true,
      onSubmit: (code) => {
        checkAndUpdate(code, userId, values);
        handleCloseCodeCheck();
      },
      onHide: () => handleCloseCodeCheck(),
      resendCode: () => sendCodeToMail(),
      theme: "success",
    });
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t("header.user.profile")}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  {t("header.user.profile")}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="card card-primary card-outline">
                <div className="card-body box-profile">
                  <div className="text-center">
                    <img
                      className="profile-user-img img-fluid img-circle"
                      src="/img/default-profile.png"
                      alt="User profile"
                    />
                  </div>
                  <h3 className="profile-username text-center">
                    {user.fullname}
                  </h3>
                  <p className="text-muted text-center">{user.email}</p>
                  <ul className="list-group list-group-unbordered mb-3">
                    <li className="list-group-item">
                      <b>{t("common.status")}</b>
                      <span className="float-right">
                        <i
                          className={`fas ${
                            !!user.activated
                              ? "fa-check-circle c-active"
                              : "fa-minus-circle c-inactive"
                          }`}
                        />
                      </span>
                    </li>
                    <li className="list-group-item">
                      <b>{t("common.websites")}</b>
                      <span className="float-right">
                        {user.own_website_ids?.length}
                      </span>
                    </li>

                    <li className="list-group-item">
                      <b>{t("common.referral")}</b>
                      <span className="float-right">{user.referral_count}</span>
                    </li>
                  </ul>
                </div>
                {/* /.card-body */}
              </div>
            </div>
            <div className="col-md-9">
              <div className="card">
                <div className="card-header p-2">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <button
                        type="button"
                        className={`nav-link ${
                          activeTab === "PRIMARY" ? "active" : ""
                        }`}
                        onClick={() => toggle("PRIMARY")}
                      >
                        {t("common.primarySettings")}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={`nav-link ${
                          activeTab === "BILLING" ? "active" : ""
                        }`}
                        onClick={() => toggle("BILLING")}
                      >
                        {t("common.billingDetails")}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={`nav-link ${
                          activeTab === "PASSWORD" ? "active" : ""
                        }`}
                        onClick={() => toggle("PASSWORD")}
                      >
                        {t("users.single.password")}
                      </button>
                    </li>
                    {user.role_id === 2 && user.mglink && (
                      <li className="nav-item">
                        <button
                          type="button"
                          className={`nav-link ${
                            activeTab === "MANAGERLINKS" ? "active" : ""
                          }`}
                          onClick={() => toggle("MANAGERLINKS")}
                        >
                          {t("common.managerLinks")}
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="card-body">
                  <div className="tab-content">
                    <PrimaryTab
                      updateUser={updateUser}
                      user={user}
                      isActive={activeTab === "PRIMARY"}
                      isLoading={isLoading}
                    />
                    <BillingTab
                      updateUser={updateUser}
                      user={user}
                      isActive={activeTab === "BILLING"}
                      isLoading={isLoading}
                      updateUserWithCode={updateUserWithCode}
                    />
                    <PasswordChangeTab
                      updatePassword={updatePassword}
                      user={user}
                      isActive={activeTab === "PASSWORD"}
                      isLoading={isLoading}
                      darkMode={darkMode}
                    />
                    {user.role_id === 2 && user.mglink && (
                      <ManagerLinksTab
                        isActive={activeTab === "MANAGERLINKS"}
                        user={user}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {codeCheck.show && (
        <CodeCheck
          title={codeCheck.title}
          show={codeCheck.show}
          onHide={codeCheck.onHide}
          onSubmit={codeCheck.onSubmit}
          resendCode={codeCheck.resendCode}
          theme={codeCheck.theme}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.theme.darkMode,
  user: state.auth.currentUser,
  isLoading: state.profile.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  sendCodeToMail: () => dispatch(SendCodeToMail()),
  checkAndUpdate: (code, userId, values) =>
    dispatch(CheckAndUpdateUser(code, userId, values)),
  updateUser: (id, data) => dispatch(UserUpdateAction(id, data)),
  updatePassword: (id, data) => dispatch(UserPasswordUpdateAction(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
