import { toast } from "react-toastify";
import { http } from "../../../helpers";
import i18n from "i18next";
import actionTypes from "../../action-types";
export const AddWebsiteAction = (url, zones, resetForm) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_ADD_LOADING, payload: true });
    try {
      const response = await http.post(`/websites`, { url, zones });
      const { bonus } = response.data;
      if (bonus) {
        dispatch(SetShowCongratulationsModalAction(true));
        dispatch({ type: actionTypes.USER_BONUS, bonus: true });
      }
      toast.success(i18n.t("website.created"));
      dispatch({ type: actionTypes.SET_ADD_LOADING, payload: false });
      resetForm();
    } catch (error) {
      const { data } = error.response;
      dispatch({ type: actionTypes.SET_ADD_LOADING, payload: false });
      toast.error(data.error_message);
    }
  };
};

export const SingleWithZones = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.WEBSITE_ZONES_LOADING, loading: true });
    try {
      // const params = {
      //     page: pageNumber ? pageNumber : 1,
      // };
      const response = await http.get(`/websites/${id}/zones`);
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_WEBSITE_ZONES, payload: data });
      dispatch({ type: actionTypes.WEBSITE_ZONES_LOADING, loading: false });
    } catch (error) {
      dispatch({ type: actionTypes.WEBSITE_ZONES_LOADING, loading: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const UserWebsites = (pageNumber) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.WEBSITES_LOADING, payload: true });
    try {
      const params = {
        page: pageNumber ? pageNumber : 1,
      };
      const response = await http.get("/websites/user-websites", { params });
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_WEBSITES, payload: data });
      dispatch({ type: actionTypes.WEBSITES_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.WEBSITES_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const GetWebsiteByIDAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_WEBSITE_LOADING, payload: true });
    try {
      const response = await http.get(`/websites/${id}`);
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_WEBSITE, payload: data });
      dispatch({ type: actionTypes.SET_WEBSITE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.SET_WEBSITE_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AddWebsiteZonesAction = (id, zones, cb) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.WEBSITE_ZONES_LOADING, loading: true });
    try {
      await http.post(`/websites/${id}/zones`, { zones });
      dispatch({ type: actionTypes.WEBSITE_ZONES_LOADING, loading: false });
      toast.success(i18n.t("zone.zonesCreated"));
      cb();
    } catch (error) {
      dispatch({ type: actionTypes.WEBSITE_ZONES_LOADING, loading: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const UpdateWebsiteAction = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.WEBSITE_UPDATE_LOADING, loading: true });
    try {
      const {
        agencyCommission,
        dcWebsiteId,
        percent,
        commercialName,
        description,
        status,
        zoneStatistics,
      } = data;
      const params = {
        url: commercialName,
        cname: commercialName,
        commission: agencyCommission,
        config: { dc_website_id: dcWebsiteId },
        percent,
        show_zone_st: zoneStatistics,
        description,
        status,
      };
      await http.post(`/admin/websites/${id}`, params);
      dispatch({ type: actionTypes.WEBSITE_UPDATE_LOADING, loading: false });
      toast.success(i18n.t("website.updated"));
    } catch (error) {
      dispatch({ type: actionTypes.WEBSITE_UPDATE_LOADING, loading: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const UpdateZoneMatchAction = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.WEBSITE_ZONE_MATCH_LOADING, loading: true });
    try {
      const sources = { ...data };
      delete sources.id;
      delete sources.zone_id;
      delete sources.created_at;
      delete sources.updated_at;
      Object.entries(sources).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          sources[key] = value.join(",");
        }
      });
      const params = {
        sources,
      };
      await http.post(`/zones/${id}/match`, params);
      dispatch({
        type: actionTypes.WEBSITE_ZONE_MATCH_LOADING,
        loading: false,
      });
      dispatch(UpdateZonesDetailsActionCreator({ id, sources }));
      toast.success(i18n.t("zone.matchUpdated"));
    } catch (error) {
      dispatch({
        type: actionTypes.WEBSITE_ZONE_MATCH_LOADING,
        loading: false,
      });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const UpdateWebsiteZonesAction = (id, zones) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.WEBSITE_ZONES_LOADING, loading: true });
    try {
      await http.put(`/websites/${id}/zones`, { zones });
      dispatch({ type: actionTypes.WEBSITE_ZONES_LOADING, loading: false });
      toast.success(i18n.t("zone.zonesUpdated"));
    } catch (error) {
      dispatch({ type: actionTypes.WEBSITE_ZONES_LOADING, loading: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const DeleteZoneAction = (id) => {
  return async (dispatch) => {
    try {
      await http.delete(`/zones/${id}`);
      dispatch({ type: actionTypes.WEBSITE_ZONE_DELETE, payload: id });
      toast.success(i18n.t("zone.deleted"));
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const UpdateZonesDetailsActionCreator = (payload) => {
  return {
    type: actionTypes.UPDATE_ZONE_DETAILS,
    payload,
  };
};

export const UpdateZoneAction = (id, details) => {
  return async () => {
    try {
      const { zoneId, zoneName } = details;
      const params = {
        zoneId,
        zoneName,
      };
      await http.put(`/websites/${id}/zones/${zoneId}`, params);
      toast.success(i18n.t("zone.updated"));
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const SetShowCongratulationsModalAction = (showCongratulationsModal) => {
  return {
    type: actionTypes.SHOW_CONGRATULATIONS_MODAL,
    payload: showCongratulationsModal,
  };
};
