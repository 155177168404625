import * as Yup from "yup";
export const patterns = {
  domain:
    /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
  cyrillicDomain:
    /^(?!:\/\/)([а-яА-Я0-9-_]+\.)*[а-яА-Я0-9][а-яА-Я0-9-_]+\.[а-яА-Я]{2,11}?$/, //|regex:/^(?!:\/\/)([a-zA-Z0-9-_]|[а-яА-Я0-9-_]+\.)*([a-zA-Z0-9-_]|[а-яА-Я0-9-_])+\.([a-zA-Z]{2,11}|[а-яА-Я]{2,11})?$/
  tMeDomain: /^t\.me\/[a-zA-Z0-9-_]+$/,
};
Yup.addMethod(Yup.string, "domain", function test(name, message) {
  const domainRules = [
    patterns.tMeDomain,
    patterns.cyrillicDomain,
    patterns.domain,
  ];
  return this.test({
    name,
    message,
    test: (value) =>
      value === null ||
      value === "" ||
      value === undefined ||
      domainRules.some((regex) => regex.test(value)),
  });
});

export default Yup;
