import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AdminWebsiteScriptUpdateAction } from "../../../store/actions/admin/scripts";
import { connect } from "react-redux";
import Editor from "../../../components/editor";

const ScriptsNew = ({ updateScripts, match }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/scripts">
                    {t("menuSidebar.label.scripts")}
                  </Link>
                </li>
                <li className="breadcrumb-item active">New</li>
              </ol>
            </div>
            <div className="col-12 text-right">
              <Link to="/admin/scripts/new" className="btn btn-primary">
                <i className="fa fa-plus-circle mr-2"></i>
                {t("script.add")}
              </Link>
            </div>
          </div>
        </section>
        <Editor match={match} updateScripts={updateScripts} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateScripts: (id, js, encodedJsCode, css, cb) =>
    dispatch(AdminWebsiteScriptUpdateAction(id, js, encodedJsCode, css, cb)),
});

export default connect(null, mapDispatchToProps)(ScriptsNew);
