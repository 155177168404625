import React, { memo, createRef, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import AceEditor from "react-ace";
import { toast } from "react-toastify";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/webpack-resolver";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Range } from "ace-builds";
import JavaScriptObfuscator from "javascript-obfuscator";
import {
  AdminGetWebsiteScriptAction,
  AdminWebsiteScriptUpdateAction,
  SetCss,
  SetEnableCss,
  SetJs,
} from "../../../store/actions/admin/scripts";
import AppButton from "../../../components/button/Button";
import { accounts, createHash, cssLink, selectText } from "../../../helpers";
import HeadScript from "../../../components/scripts/headScript";
import echo from "../../../helpers/echo";

const ScriptEdit = ({
  match,
  cssCode,
  jsCode,
  isLoading,
  enableCss,
  getScript,
  setEnableCss,
  setJs,
  setCss,
  updateScripts,
  currentUser,
}) => {
  const { params } = match;
  const { id } = params;
  const { t } = useTranslation();
  const jsEditor = createRef(null);
  const cssEditor = createRef(null);
  const [userInScript, setUserInScript] = useState([]);

  useEffect(() => {
    getScript(id);
    let usersInScript = [];
    const channel = echo(localStorage.getItem("dc-token"));
    channel
      .join(`UserActions.${createHash(id)}`)
      .here((users) => {
        usersInScript = users;
        if (users.length > 1) {
          usersInScript = usersInScript.filter(
            ({ id }) => id !== currentUser.id
          );
          setUserInScript(usersInScript);
        }
      })
      .leaving((user) => {
        if (
          usersInScript.find(
            ({ id }) =>
              (id === user.id && id !== currentUser.id) || id !== user.id
          )
        ) {
          getScript(id);
        }
        usersInScript = usersInScript.filter(
          ({ id }) => id !== user.id && id !== currentUser.id
        );
        setUserInScript(usersInScript);
      });
    return () => {
      channel.leave(`UserActions.${createHash(id)}`);
    };
  }, [id, getScript, currentUser]);

  const allUsersInScript = userInScript.map((user, index) => (
    <span key={user.id}>
      {userInScript.indexOf(userInScript.at(-1)) !== index
        ? user.fullname + ","
        : user.fullname}
      <br />
    </span>
  ));
  const handleChangeJs = (code) => {
    setJs(code);
  };
  const handleChangeCss = (code) => {
    setCss(code);
  };
  const handleCopied = () => {
    toast.info(t("common.copied"));
  };
  const selectAccount = (e) => {
    const accountId = Number(e.target.value);

    if (accountId === 0) return;

    const editor = jsEditor.current.editor;

    const foundAccount = accounts.find(({ id }) => id === accountId);

    let script = foundAccount?.script;

    if (enableCss) {
      const createCssLink = cssLink(id, true);
      script = script.concat(createCssLink);
    }
    editor.setValue(script);
  };

  const toggleCss = () => {
    const editor = jsEditor.current.editor;
    const createCssLink = cssLink(id, false);
    if (!enableCss) {
      const existLink = editor.find("/*start-css loader*/");
      if (!existLink) {
        editor.session.insert(
          {
            row: editor.session.getLength(),
            column: 0,
          },
          "\n /*start-css loader*/ \n" + createCssLink + "\n /*end-css loader*/"
        );
      }
    } else {
      let start = editor.find("/*start-css loader*/");
      let end = editor.find("/*end-css loader*/");
      if (start && start.start.row) {
        editor.session.replace(
          new Range(start.start.row - 1, end.end.row, Infinity, Infinity),
          ""
        );
      }
      setCss("");
    }
    setEnableCss(!enableCss);
  };

  const handleUpdateScript = () => {
    try {
      const annotations = jsEditor.current.editor.getSession().getAnnotations();
      if (annotations.length) {
        toast.error(`${annotations[0]?.text} at line ${annotations[0]?.row}`);
      }
      const { obfuscate } = JavaScriptObfuscator;
      const encodedJs = obfuscate(jsCode);
      const encodeJsCode = encodedJs.getObfuscatedCode();
      updateScripts(id, jsCode, encodeJsCode, cssCode);
    } catch (e) {
      console.log(e);
      toast.error(e.toString());
    }
  };

  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="row mb-2">
            <div className="col-sm-4">
              <h1>{id}</h1>
            </div>
            <div className="col-sm-4">
              {allUsersInScript.length > 0 && (
                <>
                  <span className="text-danger">
                    {t("header.notifications.scriptAlreadyBusy")}
                    {allUsersInScript}
                  </span>
                </>
              )}
            </div>
            <div className="col-sm-4">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/scripts">
                    {t("menuSidebar.label.scripts")}
                  </Link>
                </li>
                <li className="breadcrumb-item active">{id}</li>
              </ol>
            </div>
            <div className="col-12 text-right">
              <Link to="/admin/scripts/new" className="btn btn-primary">
                <i className="fa fa-plus-circle mr-2"></i>
                {t("script.add")}
              </Link>
            </div>
          </div>
        </section>
        <div className="card rounded mt-5 p-5">
          <div>
            <select
              className="custom-select"
              style={{ width: "200px" }}
              onChange={selectAccount}
              hidden
            >
              <option value={0}>{t("script.chooseAccount")}</option>
              {accounts.map((account) => {
                return (
                  <option value={account.id} key={account.id}>
                    {account.name}
                  </option>
                );
              })}
            </select>
            <div className="mt-2">
              <input
                onChange={toggleCss}
                checked={enableCss}
                type="checkbox"
                id="inludeCss"
              />
              <label htmlFor="inludeCss" className="ml-2">
                {t("script.enableCss")}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-6">
              <h3>JS</h3>
              <AceEditor
                ref={jsEditor}
                mode="javascript"
                theme="monokai"
                onChange={handleChangeJs}
                fontSize={12}
                value={jsCode}
                name="editor"
                height="700px"
                width="100%"
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: true,
                  readOnly: allUsersInScript.length,
                }}
              />
            </div>
            <div className="col-12 col-xl-6">
              {enableCss && (
                <>
                  <h3>CSS</h3>
                  <AceEditor
                    ref={cssEditor}
                    mode="css"
                    theme="monokai"
                    onChange={handleChangeCss}
                    fontSize={12}
                    name="css-editor"
                    height="700px"
                    width="100%"
                    value={cssCode}
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      readOnly: allUsersInScript.length,
                    }}
                  />
                </>
              )}
            </div>

            <div className="col-12 mt-3 text-right">
              <AppButton onClick={handleUpdateScript}>
                {t("script.updateScript")}
              </AppButton>
            </div>
          </div>
        </div>
        <div className="mt-3 px-0 pb-5 col-md-6 col-12">
          <div className="card">
            <div className="card-header">
              <Trans i18nKey="script.loaderJsNote">
                In the <code> head </code> of the site, add the loader code once
              </Trans>
            </div>
            <div className="card-body table-responsive p-0">
              <div id="header-links">
                <HeadScript url={id} />
              </div>
            </div>
            <AppButton
              isLoading={isLoading}
              onClick={() => selectText("header-links", true, handleCopied)}
            >
              {t("common.copy")}
            </AppButton>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const {
    cssCode,
    jsCode,
    isLoading,
    enableCss,
    enableAdfoxRefresh,
    adfoxIds,
    interval,
  } = state.adminWebsiteScript;
  return {
    cssCode,
    jsCode,
    isLoading,
    enableCss,
    enableAdfoxRefresh,
    adfoxIds,
    interval,
    currentUser: state.auth.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getScript: (id) => dispatch(AdminGetWebsiteScriptAction(id)),
  setEnableCss: (css) => dispatch(SetEnableCss(css)),
  setJs: (code) => dispatch(SetJs(code)),
  setCss: (code) => dispatch(SetCss(code)),
  updateScripts: (id, js, encodeJsCode, css) =>
    dispatch(AdminWebsiteScriptUpdateAction(id, js, encodeJsCode, css)),
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(ScriptEdit));
