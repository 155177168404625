import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import WebsiteListFilter from "../../../components/filter/websiteListFilter";
import NoData from "../../../components/noData";
import PageLoading from "../../../components/page-loading/PageLoading";
import { AdminGetActiveWebsitesAction } from "../../../store/actions/admin/websites";
const AdminActiveWebsites = ({
  data,
  current_page,
  per_page,
  total,
  isLoading,
  getWebsites,
  match,
}) => {
  const { t } = useTranslation();
  const [showUsers, setShowUsers] = useState(new Set());
  const [showSources, setShowSources] = useState(new Set());
  const [filterEmail, setFilterEmail] = useState("");
  const [filterWebsite, setFilterWebsite] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterManager, setFilterManager] = useState(null);
  const [filterPeriod, setFilterPeriod] = useState("yesterday");
  const [order, setOrder] = useState({
    orderBy: "commission",
    ascing: true,
  });

  useEffect(() => {
    getWebsites(
      filterPeriod,
      null,
      filterEmail,
      filterWebsite,
      filterStatus,
      filterManager,
      order
    );
  }, [
    getWebsites,
    filterPeriod,
    filterEmail,
    filterWebsite,
    filterStatus,
    filterManager,
    order,
  ]);

  const handleOrder = useCallback((orderBy) => {
    setOrder((prevState) => {
      return {
        orderBy,
        ascing: prevState.orderBy !== orderBy ? true : !prevState.ascing,
      };
    });
  }, []);

  const toggleShowSources = (id) => {
    const newShowSources = new Set(showSources);
    if (showSources.has(id)) {
      newShowSources.delete(id);
    } else {
      newShowSources.add(id);
    }
    setShowSources(newShowSources);
  };

  const toggleShowUsers = (websiteId) => {
    let newShowUsers = new Set(showUsers);
    if (showUsers.has(websiteId)) {
      newShowUsers.delete(websiteId);
    } else {
      newShowUsers.add(websiteId);
    }
    setShowUsers(newShowUsers);
  };

  const websitesList = data.map((website) => {
    return (
      <tr
        key={website.id}
        style={{
          backgroundColor: website.commission_perc === 0 ? "#fdac4130" : "",
        }}
      >
        <td>
          <Link to={`/admin/websites/${website.id}`}>{website.id}</Link>
        </td>
        <td>
          <span className="ml-2">
            <Link to={`/admin/websites/${website.id}`}>{website.url}</Link>
          </span>
        </td>
        <td>
          <Link
            className="btn-link"
            to={`/admin/users/${website.main_user?.id}`}
          >
            {website.main_user?.email}
          </Link>
          {!!website.users?.length && (
            <span
              className="more-users"
              onClick={() => toggleShowUsers(website.id)}
            >
              <i
                className={`fa fa-${
                  showUsers.has(website.id) ? "minus" : "plus"
                }-circle mr-2`}
              ></i>
            </span>
          )}
          {website.users?.map((user, i) => {
            return (
              <span
                className={`dividerV ${
                  !showUsers.has(website.id) ? "d-none" : ""
                }`}
                key={user.id}
              >
                <Link to={`/admin/users/${user.id}`}>{user.fullname}</Link>
              </span>
            );
          })}
        </td>
        <td>{website.manager}</td>
        <td>
          {website.sources?.map((source, i) => {
            return (
              <span
                className={`dividerV ${
                  i > 1 && !showSources.has(website.id) ? "d-none" : ""
                }`}
                key={source.id}
              >
                {source.title}
              </span>
            );
          })}
          {website.sources?.length > 1 && (
            <span
              className="more-sources"
              onClick={() => toggleShowSources(website.id)}
            >
              <i
                className={`fa fa-${
                  showSources.has(website.id) ? "minus" : "plus"
                }-circle mr-2`}
              ></i>
            </span>
          )}
        </td>
        <td>{website.sum_usd}</td>
        <td>{website.sum_bonus}</td>
        <td>{website.cpm_usd}</td>
        <td>{website.commission_perc}</td>
      </tr>
    );
  });
  let template = isLoading ? (
    <PageLoading />
  ) : (
    <>
      {!!websitesList.length ? (
        <div className="card">
          <div className="card-body table-responsive p-0">
            <table className="table table-striped table-valign-middle table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{t("website.label.domain")}</th>
                  <th>{t("menuSidebar.label.users")}</th>
                  <th>{t("website.label.manager")}</th>
                  <th>{t("menuSidebar.label.sources")}</th>
                  <th className="c-pointer" onClick={() => handleOrder("sum")}>
                    {t("website.label.usd")}
                    <i className="fas fa-sort ml-2"></i>
                  </th>
                  <th>{t("website.label.bonus")}</th>
                  <th className="c-pointer" onClick={() => handleOrder("cpm")}>
                    {t("website.label.cpm")}
                    <i className="fas fa-sort ml-2"></i>
                  </th>
                  <th
                    className="c-pointer"
                    onClick={() => handleOrder("commission")}
                  >
                    {t("website.label.commission")}
                    <i className="fas fa-sort ml-2"></i>
                  </th>
                </tr>
              </thead>
              <tbody>{websitesList}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <NoData />
      )}
    </>
  );

  return (
    <div className="container-fluid">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t("menuSidebar.label.activeWebsites")}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/admin">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  {t("menuSidebar.label.activeWebsites")}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <WebsiteListFilter
        filterEmail={filterEmail}
        filterWebsite={filterWebsite}
        filterStatus={filterStatus}
        filterManager={filterManager}
        filterPeriod={filterPeriod}
        filterOrder={order}
        setFilterEmail={setFilterEmail}
        setFilterWebsite={setFilterWebsite}
        setFilterStatus={setFilterStatus}
        setFilterManager={setFilterManager}
        setFilterPeriod={setFilterPeriod}
        handleApply={getWebsites}
        isLoading={isLoading}
      />
      {template}
      {total > per_page && (
        <Pagination
          activePage={current_page || 0}
          itemsCountPerPage={per_page || 0}
          totalItemsCount={total || 0}
          onChange={(pageNumber) => {
            getWebsites(
              filterPeriod,
              pageNumber,
              filterEmail,
              filterWebsite,
              filterStatus,
              filterManager,
              order
            );
          }}
          pageRangeDisplayed={8}
          itemClass="page-item"
          linkClass="page-link"
          firstPageText={t("common.firstPage")}
          lastPageText={t("common.lastPage")}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { data, current_page, per_page, total, isLoading } =
    state.adminActiveWebsites;
  return {
    data,
    current_page,
    per_page,
    total,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWebsites: (...filters) =>
      dispatch(AdminGetActiveWebsitesAction(...filters)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminActiveWebsites);
