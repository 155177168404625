import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  SetWebsiteReportPeriod,
  WebsiteReport,
  ZonesReports,
} from "../../../store/actions/reports";
import Filter from "../../../components/filter";
import Image from "../../../components/image";
import LineChart from "../../../components/chart/LineChart";
import { impressionFormat, showAmountOfNumber } from "../../../helpers";
import Spinner from "../../../components/spinner";
import AppButton from "../../../components/button/Button";
import {
  DeleteZoneAction,
  GetWebsiteByIDAction,
  UpdateWebsiteAction,
  UpdateWebsiteZonesAction,
  UpdateZoneMatchAction,
} from "../../../store/actions/websites";
import MultiChart from "../../../components/chart/MultiChart";
import ZonesFormList from "./zones/formList";
import ColumnChart from "../../../components/chart/ColumnChart";
import { ExportWebsiteXlsxAction } from "../../../store/actions/admin/export";

const AdminWebsiteEdit = ({
  match,
  website,
  reports,
  period,
  isLoading,
  zonesIsLoading,
  zoneChart,
  zoneTotal,
  realZoneTotal,
  zoneReportList,
  getWebsiteWithZones,
  getReport,
  getZoneReport,
  setPeriod,
  updateWebsite,
  updateZoneMatch,
  updateWebsiteZones,
  deleteZone,
  darkMode,
  exportWebsiteXlsx,
}) => {
  const { t } = useTranslation();
  const { id } = match.params;
  const { zones, commission_history: commissionHistory = [] } = website;

  const commissionSeries = [
    {
      name: t("website.label.commission"),
      data: [],
    },
  ];

  const commissionCategories = [];

  commissionHistory?.forEach((el) => {
    commissionSeries.at().data.push(el?.commission);
    commissionCategories.push(el?.month);
  });

  const [activeTab, setActiveTab] = useState("source");

  const [collapse, setCollapse] = useState({
    statistic: true,
    sourceStatistic: true,
    zoneStatistic: true,
    commission: true,
  });

  useEffect(() => {
    getWebsiteWithZones(id);
    getReport(id, period);
    getZoneReport(id, period);
  }, [getReport, id, period, getZoneReport, getWebsiteWithZones]);

  const handleApply = useCallback(
    (_, picker) => {
      const pickerDate = `${picker.startDate.format(
        "YYYY-MM-DD"
      )} - ${picker.endDate.format("YYYY-MM-DD")}`;
      setPeriod(pickerDate);
    },
    [setPeriod]
  );

  const handleExport = () => {
    if (period) {
      exportWebsiteXlsx(id, period);
    }
  };

  const {
    chartMultiParams,
    chartMultiSources,
    sourceData,
    impressions,
    sum,
    realSum,
    cpm,
    sumEarnings,
    sumCommission,
    sumBonus,
  } = reports;

  const chartCIR = useMemo(
    () => ({
      series: [
        {
          name: t("dashboard.website.impressions"),
          type: "column",
          data: chartMultiParams.impsDays,
        },
        {
          name: t("dashboard.website.cpm"),
          type: "column",
          data: chartMultiParams.cpmDays,
        },
        {
          name: t("dashboard.website.profit"),
          type: "line",
          data: chartMultiParams.sumDays,
        },
      ],
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2],
        formatter: function (val) {
          return isFinite(val)
            ? val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : val;
        },
        style: {
          fontSize: "18px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
        },
      },
    }),
    [chartMultiParams, t]
  );

  const sourcesList = sourceData
    .sort(
      (a, b) =>
        parseFloat(b.real_sum.replace(",", "")) -
        parseFloat(a.real_sum.replace(",", ""))
    )
    .map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <Image
              style={{ maxHeight: "20px" }}
              name={`${item.type}-logo.png`}
            />
          </td>
          <td>{item.title}</td>
          <td className="text-bold">{item.real_sum}</td>
          <td className="text-bold">{item.sum}</td>
          <td>{item.cpm}</td>
          <td>{impressionFormat(item.impression)}</td>
          <td>{item.sum_commission}</td>
          {parseInt(sumBonus) > 0 && (
            <td className="text-bonus">{item.sum_bonus}</td>
          )}
          <td className="px-5">{item.sum_earnings}</td>
        </tr>
      );
    });

  const sourceListByDayHead = chartMultiSources.series.map((source, index) => {
    return (
      <th key={index}>
        <Image
          style={{ maxHeight: "20px" }}
          className="d-block"
          name={`${source.source}-logo.png`}
        />
        <span>{source.name}</span>
      </th>
    );
  });

  const sourceListByDayBody = Array.from(chartMultiSources.categories).map(
    (date, parentIndex) => {
      return (
        <tr key={parentIndex}>
          <td>{date}</td>
          {chartMultiSources.series.map((item, index) => {
            return (
              <td key={index}>{showAmountOfNumber(item.data[parentIndex])}</td>
            );
          })}
          <td className="text-bold">
            {showAmountOfNumber(chartMultiParams.sumDays[parentIndex])} $
          </td>
        </tr>
      );
    }
  );

  const sourceListByDayFoot = chartMultiSources.series.map((source, index) => {
    return (
      <th key={index}>
        {showAmountOfNumber(source.data.reduce((prev, curr) => prev + curr, 0))}{" "}
        $
      </th>
    );
  });

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const zonesList = zoneReportList
    .sort((a, b) => b.real_sum - a.real_sum)
    .map((item) => {
      return (
        <tr key={item.zone}>
          <td>{item.title}</td>
          <td>{item.size}</td>
          <td>{showAmountOfNumber((item.sum / zoneTotal) * 100)} %</td>
          <td className="text-bold">{showAmountOfNumber(item.sum)}</td>
          <td className="text-bold">{showAmountOfNumber(item.real_sum)}</td>
        </tr>
      );
    });

  const zonesListByDayHead = zoneChart.series.map((item) => {
    return <th key={item.zone}>{item.name}</th>;
  });

  const zonesListByDayBody = Array.from(zoneChart.categories).map(
    (date, parentIndex) => {
      return (
        <tr key={parentIndex}>
          <td>{date}</td>
          {zoneChart.series.map((item, index) => {
            return (
              <td key={index}>{showAmountOfNumber(item.data[parentIndex])}</td>
            );
          })}
          <td className="text-bold">
            {showAmountOfNumber(zoneChart.sumDays[parentIndex])} $
          </td>
        </tr>
      );
    }
  );

  const zonesListByDayFoot = zoneChart.series.map((zone, index) => {
    return (
      <th key={index}>
        {showAmountOfNumber(zone.data.reduce((prev, curr) => prev + curr, 0))} $
      </th>
    );
  });

  const configFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bonus: false,
      status: website.status,
      zoneStatistics: website.show_zone_st,
      commercialName: website.cname ?? "",
      agencyCommission: website.commission ?? 0,
      dcWebsiteId: website.config?.dc_website_id ?? "",
      percent: website.percent ?? 0,
      description: website.description ?? "",
    },
    validationSchema: Yup.object({
      bonus: Yup.boolean(),
      status: Yup.mixed().oneOf(["approved", "pending", "canceled"]),
      zoneStatistics: Yup.boolean(),
      commercialName: Yup.string().nullable(true),
      dcWebsiteId: Yup.number().nullable(true),
      percent: Yup.number().nullable(true),
      agencyCommission: Yup.number().required(t("website.validation.required")),
      description: Yup.string().nullable(true),
    }),
    onSubmit: (data) => {
      updateWebsite(id, data);
    },
  });

  const positionZoneReportListRef = useRef(0);
  const zoneReportListRef = useRef(null);
  const positionSourceReportListRef = useRef(0);
  const sourceReportListRef = useRef(null);

  const handleScrollZoneReportList = (e) => {
    const x = e.currentTarget.scrollLeft;
    if (x !== positionZoneReportListRef.current) {
      positionZoneReportListRef.current = x;

      if (!zoneReportListRef.current.className.includes("sticky")) {
        zoneReportListRef.current.className =
          zoneReportListRef.current.className + " sticky";
      }
    }
    if (x === 0) {
      zoneReportListRef.current.className =
        zoneReportListRef.current.className.replace(" sticky", "");
    }
  };

  const handleScrollSourceReportList = (e) => {
    const x = e.currentTarget.scrollLeft;
    if (x !== positionSourceReportListRef.current) {
      positionSourceReportListRef.current = x;
      if (!sourceReportListRef.current.className.includes("sticky")) {
        sourceReportListRef.current.className =
          sourceReportListRef.current.className + " sticky";
      }
    }
    if (x === 0) {
      sourceReportListRef.current.className =
        sourceReportListRef.current.className.replace(" sticky", "");
    }
  };

  const toggleCollapse = (key) => {
    setCollapse((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <div className="container-fluid">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              {
                <h1>
                  {t("website.edit")}{" "}
                  <span className="text-success">[{website.url}]</span>
                </h1>
              }
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/websites">
                    {t("menuSidebar.label.websites")}
                  </Link>
                </li>
                <li className="breadcrumb-item active">{website.url}</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <div className="card rounded">
        <div className="card-header">
          <h5>{t("dashboard.website.stat")}</h5>
        </div>
        <div className="card-body">
          <Filter
            period={period}
            setPeriod={setPeriod}
            isLoading={isLoading}
            handleApply={handleApply}
          ></Filter>
          <ul className="nav nav-tabs mb-2" role="tablist">
            <li className="nav-item">
              <button
                type="button"
                className={`nav-link ${activeTab === "source" ? "active" : ""}`}
                onClick={() => toggleTab("source")}
              >
                {t("website.label.bySource")}
              </button>
            </li>
            <li className="nav-item">
              <button
                type="button"
                className={`nav-link ${activeTab === "zone" ? "active" : ""}`}
                onClick={() => toggleTab("zone")}
              >
                {t("website.label.byZone")}
              </button>
            </li>
          </ul>
          <div className="tab-content">
            {activeTab === "source" && (
              <div className={"tab-pane active"}>
                <div className="col-xl-12 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">{t("sources.list")}</h3>
                    </div>
                    <div
                      className="card-body table-responsive p-0"
                      style={{ maxHeight: "500px" }}
                    >
                      {isLoading ? <Spinner /> : null}
                      <table className="table table-striped table-valign-middle">
                        <thead>
                          <tr>
                            <th>{t("sources.type")}</th>
                            <th>{t("sources.title")}</th>
                            <th>{t("sources.netTotal")}</th>
                            <th>{t("sources.totalAgency")}</th>
                            <th>{t("sources.cpm")}</th>
                            <th>{t("sources.impressions")}</th>
                            <th>{t("sources.commission")}</th>
                            {parseInt(sumBonus) > 0 && (
                              <th className="text-bonus">
                                {t("dashboard.website.bonus")}
                              </th>
                            )}
                            <th className="px-5">{t("sources.userProfit")}</th>
                          </tr>
                        </thead>
                        <tbody>{sourcesList}</tbody>
                        <tfoot>
                          <tr>
                            <th>{t("common.total")} (USD)</th>
                            <th></th>
                            <th>{realSum}</th>
                            <th>{sum}</th>
                            <th>{cpm}</th>
                            <th>{impressionFormat(impressions)}</th>
                            <th>{sumCommission}</th>
                            {parseInt(sumBonus) > 0 && (
                              <th className="text-bonus">{sumBonus}</th>
                            )}
                            <th className="px-5">{sumEarnings}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <AppButton
                    type="button"
                    className="btn btn-primary mb-3"
                    onClick={handleExport}
                  >
                    {t("export.label.export")}
                  </AppButton>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <h3 className="card-title">
                          {t("dashboard.website.stat")}
                        </h3>
                        <div className="collapse-icon-background">
                          <i
                            role="button"
                            className={
                              collapse.statistic
                                ? "fas fa-arrow-up"
                                : "fas fa-arrow-down"
                            }
                            onClick={() => toggleCollapse("statistic")}
                          ></i>
                        </div>
                      </div>
                    </div>
                    {collapse.statistic && (
                      <div className="card-body">
                        <MultiChart
                          loading={isLoading}
                          darkMode={darkMode}
                          series={chartCIR.series}
                          categories={chartMultiSources.categories}
                          dataLabels={chartCIR.dataLabels}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className={`card`}>
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <h3 className="card-title">
                          {t("dashboard.website.stat")}
                        </h3>
                        <div className="collapse-icon-background">
                          <i
                            role="button"
                            className={
                              collapse.sourceStatistic
                                ? "fas fa-arrow-up"
                                : "fas fa-arrow-down"
                            }
                            onClick={() => toggleCollapse("sourceStatistic")}
                          ></i>
                        </div>
                      </div>
                    </div>
                    {collapse.sourceStatistic && (
                      <div className="card-body">
                        <LineChart
                          darkMode={darkMode}
                          loading={isLoading}
                          series={chartMultiSources.series}
                          categories={chartMultiSources.categories}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-12 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <h3 className="card-title">
                          {t("website.commissionHistory")}
                        </h3>
                        <div className="collapse-icon-background">
                          <i
                            role="button"
                            className={
                              collapse.commission
                                ? "fas fa-arrow-up"
                                : "fas fa-arrow-down"
                            }
                            onClick={() => toggleCollapse("commission")}
                          ></i>
                        </div>
                      </div>
                    </div>
                    {collapse.commission && (
                      <div className="card-body">
                        <ColumnChart
                          series={commissionSeries}
                          categories={commissionCategories}
                          darkMode={darkMode}
                          loading={isLoading}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-12 col-sm-12 col-12">
                  <div className="card">
                    <div
                      ref={sourceReportListRef}
                      className="card-body table-responsive p-0"
                      style={{ maxHeight: "500px" }}
                      onScroll={handleScrollSourceReportList}
                    >
                      {isLoading ? <Spinner /> : null}
                      <table className="table table-striped table-valign-middle table-sm">
                        <thead>
                          <tr>
                            <th>{t("common.date")}</th>
                            {sourceListByDayHead}
                            <th>{t("common.total")}</th>
                          </tr>
                        </thead>
                        <tbody>{sourceListByDayBody}</tbody>
                        <tfoot>
                          <tr>
                            <th>{t("common.total")}</th>
                            {sourceListByDayFoot}
                            <th>{sum}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === "zone" && (
              <div className={"tab-pane active"}>
                <div className="col-xl-12 col-sm-12 col-12 mt-4">
                  <div className="card">
                    <div
                      className="card-body table-responsive p-0"
                      style={{ maxHeight: "500px" }}
                    >
                      {zonesIsLoading ? <Spinner /> : null}
                      <table className="table table-striped table-valign-middle table-sm">
                        <thead>
                          <tr>
                            <th>{t("sources.title")}</th>
                            <th>{t("sources.type")}</th>
                            <th>{t("zones.efficiency")}</th>
                            <th>{t("sources.profit")}</th>
                            <th>{t("sources.netTotal")}</th>
                          </tr>
                        </thead>
                        <tbody>{zonesList}</tbody>
                        <tfoot>
                          <tr>
                            <th>{t("common.total")}</th>
                            <th></th>
                            <th></th>
                            <th>{showAmountOfNumber(zoneTotal)}</th>
                            <th>{showAmountOfNumber(realZoneTotal)}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className={`card`}>
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <h3 className="card-title">
                          {t("dashboard.website.stat")}
                        </h3>
                        <div className="collapse-icon-background">
                          <i
                            role="button"
                            className={
                              collapse.zoneStatistic
                                ? "fas fa-arrow-up"
                                : "fas fa-arrow-down"
                            }
                            onClick={() => toggleCollapse("zoneStatistic")}
                          ></i>
                        </div>
                      </div>
                    </div>

                    {collapse.zoneStatistic && (
                      <div className="card-body">
                        <LineChart
                          darkMode={darkMode}
                          loading={zonesIsLoading}
                          series={zoneChart.series}
                          categories={zoneChart.categories}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-12 col-sm-12 col-12">
                  <div className="card">
                    <div
                      ref={zoneReportListRef}
                      className="card-body table-responsive p-0"
                      style={{ maxHeight: "500px" }}
                      onScroll={handleScrollZoneReportList}
                    >
                      {zonesIsLoading ? <Spinner /> : null}
                      <table className="table table-striped table-valign-middle text-center zone-report-list">
                        <thead>
                          <tr>
                            <th width="250">{t("common.date")}</th>
                            {zonesListByDayHead}
                            <th>{t("common.total")}</th>
                          </tr>
                        </thead>
                        <tbody>{zonesListByDayBody}</tbody>
                        <tfoot>
                          <tr>
                            <th>{t("common.total")}</th>
                            {zonesListByDayFoot}
                            <th>{showAmountOfNumber(zoneTotal)}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card rounded">
        <div className="card-header">
          <h5>{t("common.settings")}</h5>
        </div>
        <div className="card-body">
          <form className="horizontal" onSubmit={configFormik.handleSubmit}>
            <div className="form-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-check-label text-bold">
                      {t("website.label.status")}
                    </label>
                    <div className="form-check">
                      <input
                        id="approved"
                        type="radio"
                        name="status"
                        className="form-check-input"
                        checked={configFormik.values.status === "approved"}
                        {...configFormik.getFieldProps("status")}
                        value="approved"
                      />
                      <label htmlFor="approved" className="form-check-label">
                        {t("website.approved")}
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        id="pending"
                        type="radio"
                        name="status"
                        className="form-check-input"
                        {...configFormik.getFieldProps("status")}
                        checked={configFormik.values.status === "pending"}
                        value="pending"
                      />
                      <label htmlFor="pending" className="form-check-label">
                        {t("website.pending")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        id="canceled"
                        type="radio"
                        name="status"
                        className="form-check-input"
                        {...configFormik.getFieldProps("status")}
                        checked={configFormik.values.status === "canceled"}
                        value="canceled"
                      />
                      <label htmlFor="canceled" className="form-check-label">
                        {t("website.canceled")}
                      </label>
                    </div>
                    <div className="custom-control custom-switch">
                      <input
                        id="zoneStatistics"
                        type="checkbox"
                        name="zoneStatistics"
                        className="custom-control-input"
                        checked={configFormik.values.zoneStatistics}
                        {...configFormik.getFieldProps("zoneStatistics")}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="zoneStatistics"
                      >
                        {t("website.label.zoneStatistics")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label htmlFor="commercialName">
                      {t("website.label.commercialName")}
                    </label>
                    <input
                      id="commercialName"
                      type="text"
                      name="commercialName"
                      className="form-control"
                      placeholder={t("website.label.commercialName")}
                      {...configFormik.getFieldProps("commercialName")}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="agencyCommission">
                      {t("website.label.agencyCommission")}
                    </label>
                    <input
                      id="agencyCommission"
                      type="text"
                      name="agencyCommission"
                      className="form-control"
                      placeholder={t("website.label.agencyCommission")}
                      {...configFormik.getFieldProps("agencyCommission")}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dcWebsiteId">
                      {t("website.label.dcWebsiteId")}
                    </label>
                    <input
                      id="dcWebsiteId"
                      type="number"
                      name="dcWebsiteId"
                      className="form-control"
                      placeholder={t("website.label.dcWebsiteId")}
                      {...configFormik.getFieldProps("dcWebsiteId")}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="percent">
                      {t("website.label.percent")}
                    </label>
                    <input
                      id="percent"
                      type="number"
                      name="percent"
                      pattern="[0-9]*"
                      min="-100"
                      max="100"
                      step="0.05"
                      className="form-control"
                      placeholder={t("website.label.percent")}
                      {...configFormik.getFieldProps("percent")}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label htmlFor="description">
                      {t("website.label.description")}
                    </label>
                    <textarea
                      id="description"
                      rows={6}
                      name="description"
                      className="form-control"
                      placeholder={t("website.label.description")}
                      {...configFormik.getFieldProps("description")}
                    />
                  </div>
                </div>
              </div>
              <div>
                <AppButton type="submit" className="btn btn-primary">
                  {t("website.save")}
                </AppButton>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="card rounded">
        <div className="card-header d-flex align-items-center">
          <h5>{t("common.zones")}</h5>
          <Link
            to={`/admin/websites/${id}/zones/add`}
            className="btn btn-primary ml-2"
          >
            <i className="fa fa-plus-circle mr-2" />
            {t("zone.add")}
          </Link>
          <Link
            to={`/admin/websites/${id}/zones/matching`}
            className="btn btn-primary ml-2"
          >
            <i className="fas fa-network-wired mr-2"></i>
            {t("zone.matching")}
          </Link>
        </div>
        <div className="card-body">
          {!zonesIsLoading && (
            <ZonesFormList
              zones={zones}
              id={id}
              updateWebsiteZones={updateWebsiteZones}
              deleteZone={deleteZone}
              updateZoneMatch={updateZoneMatch}
            />
          )}
        </div>
        <div className="card-body"></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    website: state.website,
    reports: state.websiteReport.reports,
    period: state.websiteReport.period,
    isLoading: state.websiteReport.isLoading,
    zonesIsLoading: state.zonesReports.isLoading,
    zoneChart: state.zonesReports.chart,
    zoneTotal: state.zonesReports.total,
    realZoneTotal: state.zonesReports.realTotal,
    zoneReportList: state.zonesReports.list,
    darkMode: state.theme.darkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWebsiteWithZones: (id) => dispatch(GetWebsiteByIDAction(id)),
    getReport: (id, period) => dispatch(WebsiteReport(id, period)),
    setPeriod: (period) => dispatch(SetWebsiteReportPeriod(period)),
    getZoneReport: (id, period) => dispatch(ZonesReports(id, period)),
    updateWebsite: (id, data) => dispatch(UpdateWebsiteAction(id, data)),
    updateZoneMatch: (id, data) => dispatch(UpdateZoneMatchAction(id, data)),
    updateWebsiteZones: (id, data) =>
      dispatch(UpdateWebsiteZonesAction(id, data)),
    deleteZone: (id) => dispatch(DeleteZoneAction(id)),
    exportWebsiteXlsx: (id, date) => {
      dispatch(ExportWebsiteXlsxAction(id, date));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminWebsiteEdit);
