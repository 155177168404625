import React from "react";

const HeadScript = ({ url }) => {
  return (
    <code>
      <pre>
        <span className="text-bold">
          &lt;script async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"&gt;
        </span>
        <span className="text-bold">&lt;/script&gt;</span>
        <br />
        <span className="text-bold">
          &lt;script async
          src="https://ads.digitalcaramel.com/prebid.js"&gt;&lt;/script&gt;
        </span>
      </pre>
    </code>
  );
};
export default HeadScript;
