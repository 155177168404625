import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AppImportFile from "../../../components/importFile";

const Import = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t("menuSidebar.label.import")}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/admin">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  {t("menuSidebar.label.import")}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <AppImportFile
                url="/import/reports"
                fileNameRegex={/\.xlsx$/i}
                fileTypeError={t("import.message.typeError")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Import;
