import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  AdminSourceSingleAction,
  AdminUpdateSourceAction,
} from "../../../store/actions/admin/sources";
import PageLoading from "../../../components/page-loading/PageLoading";
import { sourceTitles } from "../../../helpers";
import AppButton from "../../../components/button/Button";
import styles from "./index.module.scss";
import { currencies } from "../../../helpers/currency";

const EditSource = ({ match, isLoading, source, getSource, editSource }) => {
  const { id } = match.params;
  const [t] = useTranslation();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [percent, setPercent] = useState(0);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [token_json, setTokenJson] = useState("");
  const [currency_id, setCur] = useState(1);
  const [status, setStatus] = useState(0);
  const [error, setError] = useState(true);
  const [submitted, setSubmit] = useState(false);

  useEffect(() => {
    getSource(id);
  }, [id, getSource]);

  useEffect(() => {
    setTitle(source.title);
    setDescription(source.description ?? "");
    setType(source.type);
    setPercent(source.percent);
    setLogin(source.login);
    setPassword(source.password);
    setToken(source.token);
    setTokenJson(source.token_json);
    setCur(source.currency_id);
    setStatus(source.status);
  }, [source]);

  const handleChange = (event) => {
    let { value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setPercent(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (!title || !type || percent < 0 || percent > 100) {
      setError(true);
      return;
    }
    editSource(id, {
      title,
      description,
      type,
      percent,
      login,
      password,
      token,
      token_json,
      currency_id,
      status,
    });
  };

  if (isLoading) {
    return <PageLoading />;
  } else {
    return (
      <div className="container">
        <div className="card m-3">
          <div className="card-header">
            <h5 className="card-title">{t("sources.edit")}</h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">{t("sources.type")}</label>
                <select
                  className={`form-control ${
                    error && submitted && !type ? "is-invalid" : ""
                  }`}
                  id="title"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option>{t("sources.type")}</option>
                  {Object.keys(sourceTitles).map((key) => {
                    return (
                      <option key={key} value={key}>
                        {sourceTitles[key]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="percent">{t("sources.percent")}</label>
                <input
                  type="number"
                  pattern="[0-9]*"
                  className={`form-control ${
                    (error && submitted && percent > 100) || percent < 0
                      ? "is-invalid"
                      : ""
                  } ${styles}`}
                  id="percent"
                  placeholder={t("sources.percent")}
                  value={percent}
                  min="0"
                  max="100"
                  step="0.05"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="title">{t("sources.title")}</label>
                <input
                  type="text"
                  className={`form-control ${
                    error && submitted && !title ? "is-invalid" : ""
                  }`}
                  id="title"
                  placeholder={t("sources.title")}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="currency_id">{t("sources.cur")}</label>
                <select
                  className="form-control"
                  id="currency_id"
                  value={currency_id}
                  onChange={(e) => setCur(e.target.value)}
                >
                  <option>{t("sources.cur")}</option>
                  {Object.keys(currencies).map((key) => {
                    return (
                      <option key={key} value={key}>
                        {currencies[key]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="status">{t("sources.status")}</label>
                <div className="form-switch">
                  <input
                    className="form-group"
                    type="checkbox"
                    id="status"
                    checked={status}
                    onChange={(e) => setStatus(!status)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="description">{t("sources.description")}</label>
                <textarea
                  className="form-control"
                  id="description"
                  rows="3"
                  placeholder={t("sources.description")}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="login">{t("sources.login")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="login"
                  placeholder={t("sources.login")}
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">{t("sources.password")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  placeholder={t("sources.password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="token">{t("sources.token")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="token"
                  placeholder={t("sources.token")}
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                />
              </div>
              {source.type === "dc" && (
                <div className="form-group">
                  <label htmlFor="token">{t("sources.tokenJson")}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="token"
                    placeholder={t("sources.tokenJson")}
                    value={token_json}
                    onChange={(e) => setTokenJson(e.target.value)}
                  />
                </div>
              )}
              <AppButton type="submit"> {t("sources.update")} </AppButton>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  const { isLoading, source } = state.adminSingleSource;
  return { isLoading, source };
};

const mapDispatchToProps = (dispatch) => ({
  editSource: (id, data) => dispatch(AdminUpdateSourceAction(id, data)),
  getSource: (id) => dispatch(AdminSourceSingleAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(EditSource));
