const actionTypes = Object.freeze({
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  LOAD_USER: "LOAD_USER",
  DASH_REPORT: "DASH_REPORT",
  AUTH_LOADING: "AUTH_LOADING",
  SET_TOAST: "SET_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST",
  REPORTS_LOADING: "REPORTS_LOADING",
  AUTH_VK_LOADING: "AUTH_VK_LOADING",
  AUTH_FB_LOADING: "AUTH_FB_LOADING",
  CHANGE_USER_PASSWORD_UPDATED: "CHANGE_USER_PASSWORD_UPDATED",
  AUTH_YANDEX_LOADING: "AUTH_YANDEX_LOADING",
  USER_ACTIVATE: "USER_ACTIVATE",
  WEBSITES_REPORTS: "WEBSITES_REPORTS",
  WEBSITES_REPORTS_LOADING: "WEBSITES_REPORTS_LOADING",
  WEBSITE_REPORTS: "WEBSITE_REPORTS",
  WEBSITE_REPORTS_LOADING: "WEBSITE_REPORTS_LOADING",
  TOGGLE_FILTER: "TOGGLE_FILTER",
  SET_PERIOD: "SET_PERIOD",
  SET_WEBSITE: "SET_WEBSITE",
  SET_WEBSITE_LOADING: "SET_WEBSITE_LOADING",
  SET_WEBSITE_PERIOD: "SET_WEBSITE_PERIOD",
  SET_ZONES: "SET_ZONES",
  SET_ZONES_PERIOD: "SET_ZONES_PERIOD",
  SET_ADMIN_SINGLE_ZONE: "SET_ADMIN_SINGLE_ZONE",
  ADMIN_SINGLE_ZONE_LOADING: "ADMIN_SINGLE_ZONE_LOADING",
  ZONES_REPORTS_LOADING: "ZONES_REPORTS_LOADING",
  ZONES_TOGGLE_FILTER: "ZONES_TOGGLE_FILTER",
  CODES_WEBSITES_LOADING: "CODES_WEBSITES_LOADING",
  WEBSITES_LOADING: "WEBSITES_LOADING",
  SET_WEBSITES: "SET_WEBSITES",
  WEBSITE_ZONES_LOADING: "WEBSITE_ZONES_LOADING",
  SET_WEBSITE_ZONES: "SET_WEBSITE_ZONES",
  WEBSITE_ZONE_DELETE: "WEBSITE_ZONE_DELETE",
  SET_ADD_LOADING: "SET_ADD_LOADING",
  REFERRAL_LOADING: "REFERRAL_LOADING",
  SET_REFERRAL_PERIOD: "SET_REFERRAL_PERIOD",
  SET_REFERRALS: "SET_REFERRALS",
  TOGGLE_DARK_MODE: "TOGGLE_DARK_MODE",
  TOGGLE_MENU_SIDEBAR: "TOGGLE_MENU_SIDEBAR",
  SET_ADMIN_USERS: "SET_ADMIN_USERS",
  ADMIN_USERS_LOADING: "ADMIN_USERS_LOADING",
  ADMIN_SINGLE_USER_LOADING: "ADMIN_SINGLE_USER_LOADING",
  SET_ADMIN_SINGLE_USER: "SET_ADMIN_SINGLE_USER",
  PROFILE_LOADING: "PROFILE_LOADING",
  SEARCH_WEBSITES: "SEARCH_WEBSITES",
  QUESTIONS_LOAD: "QUESTIONS_LOAD",
  TOGGLE_QUESTIONS_BLOCK: "TOGGLE_QUESTIONS_BLOCK",
  SINGLE_QUESTIONS: "SINGLE_QUESTIONS",
  SINGLE_QUESTIONS_LOADING: "SINGLE_QUESTIONS_LOADING",
  QUESTION_DELETE: "QUESTION_DELETE",
  QUESTION_CHANGED: "QUESTION_CHANGED",
  SET_BONUS: "SET_BONUS",
  USER_BONUS: "USER_BONUS",
  USER_BONUS_PERCENT: "USER_BONUS_PERCENT",
  SHOW_CONGRATULATIONS_MODAL: "SHOW_CONGRATULATIONS_MODAL",
  SET_ADMIN_ACTIVE_WEBSITES: "SET_ADMIN_ACTIVE_WEBSITES",
  ADMIN_ACTIVE_WEBSITES_LOADING: "ADMIN_ACTIVE_WEBSITES_LOADING",
  SET_ADMIN_SCRIPTS: "SET_ADMIN_SCRIPTS",
  SET_ADMIN_BONUS: "SET_ADMIN_BONUS",
  SET_ADMIN_BONUS_LOADING: "SET_ADMIN_BONUS_LOADING",
  ADMIN_SCRIPTS_LOADING: "ADMIN_SCRIPTS_LOADING",
  ADMIN_WEBSITE_SCRIPT_LOADING: "ADMIN_WEBSITE_SCRIPT_LOADING",
  SET_ADMIN_WEBSITE_SCRIPT: "SET_ADMIN_WEBSITE_SCRIPT",
  ADMIN_WEBSITE_SCRIPT_ENABLE_CSS: "ADMIN_WEBSITE_SCRIPT_ENABLE_CSS",
  ADMIN_WEBSITE_CHANGE_SCRIPT: "ADMIN_WEBSITE_CHANGE_SCRIPT",
  ADMIN_WEBSITE_CHANGE_CSS: "ADMIN_WEBSITE_CHANGE_CSS",
  ADMIN_WEBSITE_UPDATE_SCRIPT_LOADING: "ADMIN_WEBSITE_UPDATE_SCRIPT_LOADING",
  ADMIN_WEBSITE_SCRIPT_NEW_LOADING: "ADMIN_WEBSITE_SCRIPT_NEW_LOADING",
  ADMIN_WEBSITE_SCRIPT_NEW_ENABLE_CSS: "ADMIN_WEBSITE_SCRIPT_NEW_ENABLE_CSS",
  ADMIN_WEBSITE_CHANGE_SCRIPT_NEW: "ADMIN_WEBSITE_CHANGE_SCRIPT_NEW",
  ADMIN_WEBSITE_SCRIPT_NEW_CHANGE_CSS: "ADMIN_WEBSITE_SCRIPT_NEW_CHANGE_CSS",
  ADMIN_WEBSITE_SCRIPT_ADFOXIDS: "ADMIN_WEBSITE_SCRIPT_ADFOXIDS",
  ADMIN_WEBSITE_SCRIPT_ENABLE_AD_REFRESH:
    "ADMIN_WEBSITE_SCRIPT_ENABLE_AD_REFRESH",
  ADMIN_WEBSITE_SCRIPT_AD_REFRESH_INTERVAL:
    "ADMIN_WEBSITE_SCRIPT_AD_REFRESH_INTERVAL",
  WEBSITE_UPDATE_LOADING: "WEBSITE_UPDATE_LOADING",
  WEBSITE_ZONE_MATCH_LOADING: "WEBSITE_ZONE_MATCH_LOADING",
  UPDATE_ZONE_DETAILS: "UPDATE_ZONE_DETAILS",
  SET_USER_NOTIFICATIONS: "SET_USER_NOTIFICATIONS",
  MARK_AS_NOTIFY: "MARK_AS_NOTIFY",
  SET_ADMIN_ZONES: "SET_ADMIN_ZONES",
  SET_ADMIN_ZONE_SIZES: "SET_ADMIN_ZONE_SIZES",
  ADMIN_ZONES_LOADING: "ADMIN_ZONES_LOADING",
  ADMIN_ZONE_SIZES_LOADING: "ADMIN_ZONE_SIZES_LOADING",
  ADMIN_SOURCES_LOADING: "ADMIN_SOURCES_LOADING",
  SET_ADMIN_SOURCES: "SET_ADMIN_SOURCES",
  SET_SINGLE_ADMIN_SOURCE: "SET_SINGLE_ADMIN_SOURCE",
  ADMIN_SINGLE_SOURCE_LOADING: "ADMIN_SINGLE_SOURCE_LOADING",
  SET_ADMIN_DASHBOARD: "SET_ADMIN_DASHBOARD",
  ADMIN_DASHBOARD_LOADING: "ADMIN_DASHBOARD_LOADING",
  SET_ADMIN_ZEN: "SET_ADMIN_ZEN",
  SET_ADMIN_ZEN_BY_SOURCES: "SET_ADMIN_ZEN_BY_SOURCES",
  SET_ADMIN_ZEN_BY_ZONES: "SET_ADMIN_ZEN_BY_ZONES",
  SET_ADMIN_ZEN_BY_SIZE: "SET_ADMIN_ZEN_BY_SIZE",
  ADMIN_ZEN_BY_SIZE_LOADING: "ADMIN_ZEN_BY_SIZE_LOADING",
  ADMIN_ZEN_BY_SIZE_PERIOD: "ADMIN_ZEN_BY_SIZE_PERIOD",
  ADMIN_ZEN_BY_ZONE_SIZE: "ADMIN_ZEN_BY_ZONE_SIZE",
  SET_EXPORT_LINK: "SET_EXPORT_LINK",
  SET_ADMIN_WEBSITES: "SET_ADMIN_WEBSITES",
  ADMIN_WEBSITES_LOADING: "ADMIN_WEBSITES_LOADING",
  ADMIN_DELETE_WEBSITE: "ADMIN_DELETE_WEBSITE",
  ADMIN_DELETE_USER: "ADMIN_DELETE_USER",
});
export default actionTypes;
