import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Faq = () => {
  const [t] = useTranslation();
  const [collapse, setCollapse] = useState(null);
  return (
    <div className="container-fluid">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6 offset-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/user">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  {t("menuSidebar.label.faq")}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8 col-lg-6">
            <div
              className="section_heading text-center wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <h3>{t("faq.title")}</h3>
              <div className="line mb-3"></div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-10 col-lg-10">
            <div className="accordion faq-accordian" id="faqAccordion">
              {Array(7)
                .fill(null)
                .map((_, key) => {
                  return (
                    <div
                      key={key}
                      className="card border-0 wow fadeInUp"
                      style={{
                        animationDelay: "1s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div
                        className="card-header c-pointer"
                        onClick={() => setCollapse(key)}
                      >
                        <h6 className="mb-0 collapsed">
                          <Trans
                            i18nKey={`faq.${key}.question`}
                            t={t}
                            components={{
                              br: <br />,
                              strong: <strong></strong>,
                            }}
                          ></Trans>
                          <span className="lni-chevron-up"></span>
                        </h6>
                      </div>
                      <div
                        className={`collapse ${collapse === key ? "show" : ""}`}
                      >
                        <div className="card-body">
                          <p>
                            <Trans
                              i18nKey={`faq.${key}.answer`}
                              t={t}
                              components={{
                                br: <br />,
                                strong: <strong></strong>,
                              }}
                            ></Trans>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Faq;
