import React, { memo, useCallback, useMemo } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  entities,
  phoneRegExp,
  printFormError,
  validateCreditCardNumber,
  bitcoinRegExp,
} from "../../helpers";
import * as Yup from "yup";
import AppButton from "../button/Button";

const BillingTab = ({
  isActive,
  user,
  updateUser,
  isLoading,
  editable,
  updateUserWithCode,
}) => {
  const [t] = useTranslation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      primaryPaymentMethod: user.primary_payment_method ?? "",
      usdt: user.usdt ?? "",
      bitcoin: user.bitcoin ?? "",
      payoneer: user.payoneer ?? "",
      paypal: user.paypal ?? "",
      card: user.card_number ?? "",
      entity: user.entity ?? "",
      cardName: user.card_name ?? "",
      companyName: user.company_name ?? "",
      ITN: user.ITN ?? "",
      PSRNSP: user.PSRNSP ?? "",
      IEC: user.IEC ?? "",
      companyAddress: user.company_address ?? "",
      companyPhone: user.company_phone ?? "",
      emailFAQ: user.email_fq ?? "",
      bankRequisites: user.bank_requisites ?? "",
      generalDirectorName: user.general_director_name ?? "",
    },

    validationSchema: Yup.object({
      entity: Yup.string()
        .nullable(true)
        .required(t("users.single.validation.required")),
      primaryPaymentMethod: Yup.string()
        .nullable(true)
        .when("entity", {
          is: "physical",
          then: Yup.string().required(t("users.single.validation.required")),
        }),
      usdt: Yup.string()
        .nullable(true)
        .when("primaryPaymentMethod", {
          is: "usdt",
          then: Yup.string().required(t("users.single.validation.required")),
        }),

      bitcoin: Yup.string()
        .nullable(true)
        .matches(bitcoinRegExp, t("users.single.validation.bitcoin"))
        .when("primaryPaymentMethod", {
          is: "bitcoin",
          then: Yup.string().required(t("users.single.validation.required")),
        }),

      payoneer: Yup.mixed()
        .nullable(true)
        .when("primaryPaymentMethod", {
          is: "payoneer",
          then: Yup.string()
            .email(t("users.single.validation.payoneer"))
            .required(t("users.single.validation.required")),
        }),
      paypal: Yup.mixed()
        .nullable(true)
        .when("primaryPaymentMethod", {
          is: "paypal",
          then: Yup.string()
            .email(t("users.single.validation.paypal"))
            .required(t("users.single.validation.required")),
        }),

      card: Yup.mixed()
        .nullable(true)
        .when("primaryPaymentMethod", {
          is: "card",
          then: Yup.mixed()
            .required(t("users.single.validation.required"))
            .test("card", t("users.single.validation.card"), (cardNumber) => {
              if (cardNumber) {
                return validateCreditCardNumber(cardNumber);
              }
              return true;
            }),
        }),
      cardName: Yup.mixed()
        .nullable(true)
        .when("primaryPaymentMethod", {
          is: "card",
          then: Yup.string().required(t("users.single.validation.required")),
        }),

      companyName: Yup.mixed()
        .nullable(true)
        .when("entity", {
          is: (entity) => entity === "legal" || entity === "individual",
          then: Yup.string().required(t("users.single.validation.required")),
        }),
      companyPhone: Yup.mixed()
        .nullable(true)
        .when("entity", {
          is: (entity) => entity === "legal",
          then: Yup.string()
            .matches(phoneRegExp, t("users.single.validation.phoneNumber"))
            .required(t("users.single.validation.required")),
        }),
      ITN: Yup.mixed()
        .nullable(true)
        .when("entity", {
          is: (entity) => entity === "legal" || entity === "individual",
          then: Yup.string().required(t("users.single.validation.required")),
        }),
      PSRNSP: Yup.mixed()
        .nullable(true)
        .when("entity", {
          is: (entity) => entity === "legal" || entity === "individual",
          then: Yup.string().required(t("users.single.validation.required")),
        }),
      IEC: Yup.mixed()
        .nullable(true)
        .when("entity", {
          is: (entity) => entity === "legal" || entity === "individual",
          then: Yup.string().required(t("users.single.validation.required")),
        }),
      companyAddress: Yup.mixed()
        .nullable(true)
        .when("entity", {
          is: (entity) => entity === "legal" || entity === "individual",
          then: Yup.string().required(t("users.single.validation.required")),
        }),
      emailFAQ: Yup.mixed()
        .nullable(true)
        .when("entity", {
          is: (entity) => entity === "legal" || entity === "individual",
          then: Yup.string()
            .email(t("users.single.validation.email"))
            .required(t("users.single.validation.required")),
        }),
      bankRequisites: Yup.mixed()
        .nullable(true)
        .when("entity", {
          is: (entity) => entity === "legal" || entity === "individual",
          then: Yup.string().required(t("users.single.validation.required")),
        }),
      generalDirectorName: Yup.mixed()
        .nullable(true)
        .when("entity", {
          is: (entity) => entity === "legal",
          then: Yup.string().required(t("users.single.validation.required")),
        }),
    }),
    onSubmit: (values) => {
      editable
        ? updateUser(user.id, values)
        : updateUserWithCode(user.id, values);
    },
  });
  const handleChangeEntity = useCallback(
    (selected) => {
      formik.resetForm();
      formik.setFieldValue(
        "entity",
        selected === null ? "" : selected.target.value
      );
      if (
        selected.target.value === "individual" ||
        selected.target.value === "legal"
      ) {
        formik.setFieldValue("primaryPaymentMethod", "bank_transfer");
      } else {
        formik.setFieldValue("primaryPaymentMethod", "");
      }
    },
    [formik]
  );
  const handleChangePrimaryPaymentType = useCallback(
    (selected) => {
      formik.setFieldValue(
        "primaryPaymentMethod",
        selected === null ? "" : selected.target.value
      );
    },
    [formik]
  );
  const legalFields = useMemo(
    () => [
      {
        field: "input",
        fieldName: "companyName",
        label: t("users.single.fullNameLegalEntity"),
        attrs: {},
      },
      {
        field: "input",
        fieldName: "ITN",
        label: t("users.single.ITN"),
        attrs: {},
      },
      {
        field: "input",
        fieldName: "IEC",
        label: t("users.single.IEC"),
        attrs: {},
      },
      {
        field: "input",
        fieldName: "PSRNSP",
        label: t("users.single.PSRNSP"),
        attrs: {},
      },
      {
        field: "input",
        fieldName: "companyAddress",
        label: t("users.single.legalAddress"),
        attrs: {},
      },
      {
        field: "input",
        fieldName: "companyPhone",
        label: t("users.single.companyPhone"),
        attrs: {},
      },
      {
        field: "input",
        fieldName: "emailFAQ",
        label: t("users.single.emailFinanceQuestions"),
        attrs: {},
      },
      {
        field: "input",
        fieldName: "generalDirectorName",
        label: t("users.single.generalDirectorName"),
        attrs: {},
      },
      {
        field: "textarea",
        fieldName: "bankRequisites",
        label: t("users.single.bankRequisites"),
        attrs: { col: "30", row: 5 },
      },
    ],
    [t]
  );
  const individualFields = useMemo(
    () => [
      {
        field: "input",
        fieldName: "companyName",
        label: t("users.single.fullNameSoleProprietorship"),
        attrs: {},
      },
      {
        field: "input",
        fieldName: "ITN",
        label: t("users.single.ITN"),
        attrs: {},
      },
      {
        field: "input",
        fieldName: "IEC",
        label: t("users.single.IEC"),
        attrs: {},
      },
      {
        field: "input",
        fieldName: "PSRNSP",
        label: t("users.single.PSRNSP"),
        attrs: {},
      },
      {
        field: "input",
        fieldName: "companyAddress",
        label: t("users.single.fullAddress"),
        attrs: {},
      },
      {
        field: "input",
        fieldName: "emailFAQ",
        label: t("users.single.emailFinanceQuestions"),
        attrs: {},
      },
      {
        field: "textarea",
        fieldName: "bankRequisites",
        label: t("users.single.bankRequisites"),
        attrs: { col: "30", row: 5 },
      },
    ],
    [t]
  );
  return (
    <div className={`tab-pane ${isActive ? "active" : ""}`}>
      <form className="horizontal" onSubmit={formik.handleSubmit}>
        <div className="row py-5">
          <div className="col-lg-4">
            <label htmlFor="entity" className="labels">
              {t("users.single.entity")}
            </label>
            <select
              id="entity"
              className="custom-select"
              {...formik.getFieldProps("entity")}
              disabled={
                !editable && !user.requisites_permission && user.role_id > 2
              }
              onChange={handleChangeEntity}
            >
              <option value=""></option>
              {Object.keys(entities).map((entity) => {
                return (
                  <option key={entity} value={entity}>
                    {t(entities[entity])}
                  </option>
                );
              })}
            </select>
            {printFormError(formik, "entity")}
          </div>
          <div className="col-lg-8">
            {formik.values["entity"] === "physical" && (
              <div className="row">
                <div className="col-md-3">
                  <strong>{t("users.single.primaryMethod")}</strong>
                  {printFormError(formik, "primaryPaymentMethod")}
                  <div
                    className="row align-items-center"
                    style={{ gap: "59px" }}
                  >
                    <div className="col-12 d-flex pl-4 align-items-center"></div>
                    <div className="col-12 d-flex pl-4 align-items-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="primaryPaymentMethod"
                          id="primaryCard"
                          value="card"
                          checked={
                            formik.values["primaryPaymentMethod"] === "card"
                          }
                          onChange={handleChangePrimaryPaymentType}
                          disabled={
                            !editable &&
                            !user.requisites_permission &&
                            user.role_id > 2
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="primaryCard"
                        >
                          {t("users.single.primaryCard")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 d-flex pl-4 align-items-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="primaryPaymentMethod"
                          id="primaryUsdt"
                          value="usdt"
                          checked={
                            formik.values["primaryPaymentMethod"] === "usdt"
                          }
                          onChange={handleChangePrimaryPaymentType}
                          disabled={
                            !editable &&
                            !user.requisites_permission &&
                            user.role_id > 2
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="primaryUsdt"
                        >
                          {t("users.single.primaryUsdt")}
                        </label>
                      </div>
                    </div>

                    <div className="col-12 d-flex pl-4 align-items-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="primaryPaymentMethod"
                          id="primaryBitcoin"
                          value="bitcoin"
                          checked={
                            formik.values["primaryPaymentMethod"] === "bitcoin"
                          }
                          onChange={handleChangePrimaryPaymentType}
                          disabled={
                            !editable &&
                            !user.requisites_permission &&
                            user.role_id > 2
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="primaryBitcoin"
                        >
                          {t("users.single.primaryBitcoin")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 d-flex pl-4 align-items-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="primaryPaymentMethod"
                          id="primaryPayoneer"
                          value="payoneer"
                          checked={
                            formik.values["primaryPaymentMethod"] === "payoneer"
                          }
                          onChange={handleChangePrimaryPaymentType}
                          disabled={
                            !editable &&
                            !user.requisites_permission &&
                            user.role_id > 2
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="primaryPayoneer"
                        >
                          {t("users.single.primaryPayoneer")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 d-flex pl-4 align-items-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="primaryPaymentMethod"
                          id="primaryPaypal"
                          value="paypal"
                          checked={
                            formik.values["primaryPaymentMethod"] === "paypal"
                          }
                          onChange={handleChangePrimaryPaymentType}
                          disabled={
                            !editable &&
                            !user.requisites_permission &&
                            user.role_id > 2
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="primaryPaypal"
                        >
                          {t("users.single.primaryPaypal")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <strong>{t("users.single.requisites")}</strong>
                  <div className="col-md-12 mt-2">
                    <div>
                      <label htmlFor="card" className="labels">
                        {t("users.single.visaMasterCardNumber")}
                      </label>
                      <input
                        id="card"
                        className="form-control"
                        placeholder={t("users.single.visaMasterCardNumber")}
                        {...formik.getFieldProps("card")}
                        disabled={
                          !editable &&
                          !user.requisites_permission &&
                          user.role_id > 2
                        }
                      />
                      {printFormError(formik, "card")}
                    </div>
                    <div>
                      <label htmlFor="cardName" className="labels">
                        {t("users.single.visaMasterCardName")}
                      </label>
                      <input
                        id="cardName"
                        className="form-control"
                        placeholder={t("users.single.visaMasterCardName")}
                        {...formik.getFieldProps("cardName")}
                        disabled={
                          !editable &&
                          !user.requisites_permission &&
                          user.role_id > 2
                        }
                      />
                      {printFormError(formik, "cardName")}
                    </div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="usdt" className="labels">
                      {t("users.single.usdt")}
                    </label>
                    <input
                      id="usdt"
                      className="form-control"
                      placeholder={t("users.single.usdt")}
                      {...formik.getFieldProps("usdt")}
                      disabled={
                        !editable &&
                        !user.requisites_permission &&
                        user.role_id > 2
                      }
                    />
                    {printFormError(formik, "usdt")}
                  </div>

                  <div className="col-md-12 mt-2">
                    <label htmlFor="bitcoin" className="labels">
                      {t("users.single.bitcoin")}
                    </label>
                    <input
                      id="bitcoin"
                      className="form-control"
                      placeholder={t("users.single.bitcoin")}
                      {...formik.getFieldProps("bitcoin")}
                      disabled={
                        !editable &&
                        !user.requisites_permission &&
                        user.role_id > 2
                      }
                    />
                    {printFormError(formik, "bitcoin")}
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="payoneer" className="labels">
                      {t("users.single.payoneer")}
                    </label>
                    <input
                      id="payoneer"
                      className="form-control"
                      placeholder={t("users.single.payoneer")}
                      {...formik.getFieldProps("payoneer")}
                      disabled={
                        !editable &&
                        !user.requisites_permission &&
                        user.role_id > 2
                      }
                    />
                    {printFormError(formik, "payoneer")}
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="paypal" className="labels">
                      {t("users.single.paypal")}
                    </label>
                    <input
                      id="paypal"
                      className="form-control"
                      placeholder={t("users.single.paypal")}
                      {...formik.getFieldProps("paypal")}
                      disabled={
                        !editable &&
                        !user.requisites_permission &&
                        user.role_id > 2
                      }
                    />
                    {printFormError(formik, "paypal")}
                  </div>
                </div>
              </div>
            )}
            {formik.values["entity"] === "individual" && (
              <>
                <div className="row">
                  {individualFields.map((item) => {
                    return (
                      <div className="col-xl-6" key={item.fieldName}>
                        <label htmlFor={item.fieldName} className="labels">
                          {item.label}
                        </label>
                        <item.field
                          disabled={
                            !editable &&
                            !user.requisites_permission &&
                            user.role_id > 2
                          }
                          id={item.fieldName}
                          className="form-control"
                          placeholder={item.label}
                          {...formik.getFieldProps(item.fieldName)}
                          {...item.attrs}
                        />
                        {printFormError(formik, item.fieldName)}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {formik.values["entity"] === "legal" && (
              <>
                <div className="row">
                  {legalFields.map((item) => {
                    return (
                      <div className="col-xl-6" key={item.fieldName}>
                        <label htmlFor={item.fieldName} className="labels">
                          {item.label}
                        </label>
                        <item.field
                          disabled={
                            !editable &&
                            !user.requisites_permission &&
                            user.role_id > 2
                          }
                          id={item.fieldName}
                          className="form-control"
                          placeholder={item.label}
                          {...formik.getFieldProps(item.fieldName)}
                          {...item.attrs}
                        />
                        {printFormError(formik, item.fieldName)}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-5 text-right">
          <AppButton
            isLoading={isLoading}
            className="btn btn-primary profile-button"
            type="submit"
          >
            {t("users.single.save")}
          </AppButton>
        </div>
      </form>
    </div>
  );
};

export default memo(BillingTab);
