import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Notifications from "../../../components/tabs/Notifications";
import Additional from "../../../components/tabs/Additional";
import Questions from "../../../components/tabs/Questions";

const Settings = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("Notifications");

  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t(`menuSidebar.label.${activeTab.toLowerCase()}`)}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("menuSidebar.label.settings")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header p-2">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <button
                      type="button"
                      className={`nav-link ${
                        activeTab === "Notifications" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("Notifications")}
                    >
                      {t("menuSidebar.label.notifications")}
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      type="button"
                      className={`nav-link ${
                        activeTab === "Questions" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("Questions")}
                    >
                      {t("menuSidebar.label.questions")}
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      type="button"
                      className={`nav-link ${
                        activeTab === "Additional" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("Additional")}
                    >
                      {t("menuSidebar.label.additional")}
                    </button>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div
                  className={`tab-content ${
                    activeTab === "Notifications" ? "d-block" : "d-none"
                  }`}
                >
                  <Notifications isActive={"Notifications"} />
                </div>

                <div
                  className={`tab-content ${
                    activeTab === "Questions" ? "d-block" : "d-none"
                  }`}
                >
                  <Questions isActive={"Questions"} />
                </div>

                <div
                  className={`tab-content ${
                    activeTab === "Additional" ? "d-block" : "d-none"
                  }`}
                >
                  <Additional isActive={"Additional"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// TODO show bonus in settings and create settings
// const mapStateToProps = (state) => {
//   return {
//     bonuses: state.adminBonus.allBonuses,
//     dateInterval: state.adminBonus.interval,
//     isLoading: state.adminBonus.isLoading,
//   };
// };
// const mapDispatchToProps = (dispatch) => {
//   return {
//     getBonuses: () => dispatch(AdminGetBonuses()),
//     setSettings: (fields) => dispatch(AdminSetSettings(fields)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(memo(Settings));
export default Settings;
