import React from "react";
import { useTranslation, Trans } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 className="display-1 fw-bold">{t("404.title")}</h1>
        <p className="fs-3">
          <Trans i18nKey="404.message">
            {""}
            <span className="text-danger">Oops!</span> Page not found.
          </Trans>
        </p>
        <p className="lead">{t("404.description")}</p>
      </div>
    </div>
  );
};
export default NotFound;
