import actionTypes from "../../action-types";
const initialState = {
  isLoading: false,
  source: {},
};

const adminSingleSourceReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_SINGLE_ADMIN_SOURCE:
      return {
        ...state,
        source: payload,
      };

    case actionTypes.ADMIN_SINGLE_SOURCE_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default adminSingleSourceReducer;
