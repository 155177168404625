import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LineChart from "../../../../components/chart/LineChart";
import { WebsitesReports } from "../../../../store/actions/reports";
import PageLoading from "../../../../components/page-loading/PageLoading";
import DateFilter from "../../../../components/filter/dateFilter";

const WebsitesReport = (props) => {
  const { user, reports, isLoading, websitesReports, darkMode } = props;
  const [t] = useTranslation();
  const [period, setPeriod] = useState("month");
  const [collapse, setCollapse] = useState(true);

  useEffect(() => {
    //if (reports.total === 0)
    websitesReports(period);
  }, [period, websitesReports]);
  const { total, chartMultisite } = reports;
  const websitesList = chartMultisite.series
    .sort(
      (a, b) =>
        parseFloat(b.sum_usd.replace(",", "")) -
        parseFloat(a.sum_usd.replace(",", ""))
    )
    .map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <Link
              to={`/user/websites/${item.id}/reports`}
              className="text-bold"
            >
              {item.name}
            </Link>
          </td>
          <td>{item.sum_usd}</td>
          {user.userBonus && <td className="text-bonus">{item.sum_bonus}</td>}
          <td>{item.impressions_usd}</td>
          <td>{item.cpm_usd}</td>
          <td>
            <Link
              to={`/user/websites/${item.id}/reports`}
              className="text-muted"
            >
              <i className="fas fa-chart-line"></i>
            </Link>
          </td>
        </tr>
      );
    });
  let template;
  if (isLoading) {
    template = <PageLoading />;
  } else {
    template = (
      <>
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className={`card`}>
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <h3 className="card-title">{t("dashboard.website.stat")}</h3>
                  <div className="collapse-icon-background">
                    <i
                      role="button"
                      className={
                        collapse ? "fas fa-arrow-up" : "fas fa-arrow-down"
                      }
                      onClick={() => setCollapse(!collapse)}
                    ></i>
                  </div>
                </div>
              </div>
              {collapse && (
                <div className="card-body">
                  <LineChart
                    isLoading={isLoading}
                    series={chartMultisite.series}
                    categories={chartMultisite.categories}
                    darkMode={darkMode}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-12 col-12">
            <div className="mb-3">
              <Link to="/user/websites/new" className="btn btn-primary">
                <i className="fa fa-plus-circle mr-2"></i>
                {t("websites.button.add")}
              </Link>
            </div>
            <div className="card">
              <div className="card-body table-responsive p-0">
                <table className="table table-striped table-valign-middle">
                  <thead>
                    <tr>
                      <th>{t("dashboard.website.name")}</th>
                      <th>{t("dashboard.website.profit")}</th>
                      {user.userBonus && (
                        <th className="text-bonus">
                          {t("dashboard.website.bonus")}
                        </th>
                      )}
                      <th>{t("dashboard.website.impressions")}</th>
                      <th>{t("dashboard.website.cpm")}</th>
                      <th>{t("dashboard.website.more")}</th>
                    </tr>
                  </thead>
                  <tbody>{websitesList}</tbody>
                  <tfoot>
                    <tr>
                      <th>{t("common.total")}</th>
                      <th>{total.sum}</th>
                      {user.userBonus && (
                        <th className="text-bonus">{total.bonus}</th>
                      )}
                      <th>{total.impressions}</th>
                      <th>{total.cpm}</th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t("menuSidebar.label.reports")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/user">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("dashboard.website.list")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <DateFilter handleApply={setPeriod} period={period}>
          <div className="ml-auto list-unstyled p-1">
            <Link to="/user/websites/new" className="btn btn-primary">
              <i className="fa fa-plus-circle mr-2"></i>
              {t("websites.button.add")}
            </Link>
          </div>
        </DateFilter>
        {template}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.currentUser,
    reports: state.websitesReports.reports,
    isLoading: state.websitesReports.isLoading,
    darkMode: state.theme.darkMode,
  };
};
const mapDispatchToProps = (dispatch) => ({
  websitesReports: (period) => dispatch(WebsitesReports(period)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WebsitesReport);
