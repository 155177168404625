import { toast } from "react-toastify";
import { http } from "../../../helpers";
import i18n from "i18next";
import actionTypes from "../../action-types";
import camelToKebab from "../../../helpers/camelToKebab";

export const ExportXlsxAction = (exportType, date) => {
  let url = `export/${camelToKebab(exportType)}`;

  return async (dispatch) => {
    try {
      const params = {
        isAgency: exportType === "agency",
        period: date,
      };
      await http.post(`/reports/${url}`, params);
      toast.success(`${i18n.t("common.requestSendWaitNotify")}`, {
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const ExportWebsiteXlsxAction = (id, date) => {
  return async () => {
    try {
      const params = {
        id,
        period: date,
      };
      await http.post(`/reports/export/website-sources`, params);
      toast.success(`${i18n.t("common.requestSendWaitNotify")}`, {
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const SetExportLink = (payload) => {
  return {
    type: actionTypes.SET_EXPORT_LINK,
    payload,
  };
};
