import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import Main from "./modules/main/Main";
import Referral from "./modules/referral/Referral";
import Login from "./modules/login/Login";
import SocialLogin from "./modules/login/SocialLogin";
import Register from "./modules/register/Register";
import Confirm from "./modules/confirm-email";
import Confirmed from "./modules/confirmed";
import ForgetPassword from "./modules/forgot-password/ForgotPassword";
import RecoverPassword from "./modules/recover-password/RecoverPassword";
import PrivacyPolicy from "./modules/privacy-policy/PrivacyPolicy";
import { ToastContainer } from "react-toastify";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import { history, isMaintenance } from "./helpers";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./App.scss";
import { ThemProvider } from "./contexts/ThemeContext";
import Maintenance from "./modules/maintenance";
const App = () => {
  return (
    <>
      <ThemProvider>
        <Router history={history}>
          <Switch>
            {isMaintenance() && <Redirect to="/maintenance" />}
            <PublicRoute exact path="/referral" component={Referral} />
            <PublicRoute exact path="/confirm" component={Confirm} />
            <PublicRoute exact path="/confirmed/:token" component={Confirmed} />
            <PublicRoute exact path="/login" component={Login} />
            <PublicRoute
              exact
              path="/auth/social-callback"
              component={SocialLogin}
            />
            <PublicRoute exact path="/register" component={Register} />
            <PublicRoute
              exact
              path="/forgot-password"
              component={ForgetPassword}
            />
            <PublicRoute
              exact
              path="/recover-password/:token"
              component={RecoverPassword}
            />
            <PublicRoute
              exact
              path="/privacy-policy"
              component={PrivacyPolicy}
            />
            <PrivateRoute path="/" component={Main} />
          </Switch>
          <Route exact path="/maintenance" component={Maintenance} />
        </Router>
      </ThemProvider>
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default App;
