import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppButton from "../../../components/button/Button";
import ManagerList from "../../../components/manager-list";
import ReconfirmModal from "../../../components/modals/reconfirm";
import PageLoading from "../../../components/page-loading/PageLoading";
import { entities, userRoles } from "../../../helpers";
import {
  AdminDeleteUsersAction,
  AdminUsersAction,
} from "../../../store/actions/admin/users";
import { UserUpdateAction } from "../../../store/actions/users";
const AdminUsers = ({
  getUsers,
  data,
  current_page,
  per_page,
  total,
  isLoading,
  width,
  updateUser,
  deleteUser,
}) => {
  const [t] = useTranslation();
  const [filterShow, setFilterShow] = useState(true);
  const [filterEmail, setFilterEmail] = useState("");
  const [filterRole, setFilterRole] = useState("");
  const [filterEntity, setFilterEntity] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [inEditMode, setInEditMode] = useState(null);
  const [showWebsites, setShowWebsites] = useState(new Set());
  const [unActiveUsers, setInactiveUsers] = useState([]);
  const [notInForumUsers, setNotInForumUsers] = useState([]);
  const [filterManager, setFilterManager] = useState(null);
  const [reconfirmModal, setReconfirmModal] = useState({
    show: false,
    title: null,
    onHide: null,
    onSubmit: null,
    theme: "",
  });

  const handleCloseReconfirmModal = () => {
    setReconfirmModal({
      title: "",
      show: false,
      onSubmit: () => {},
      onHide: () => {},
    });
  };

  const handleDeleteUser = useCallback(
    (e, user) => {
      e.stopPropagation();
      setReconfirmModal({
        title: t("common.deleteUser") + user.email,
        show: true,
        onSubmit: () => {
          deleteUser(user.id);
          handleCloseReconfirmModal();
        },
        onHide: () => handleCloseReconfirmModal(),
        theme: "success",
      });
    },
    [t, deleteUser]
  );

  const handleEditUser = useCallback((e, id) => {
    e.stopPropagation();
    setInEditMode(id);
  }, []);

  const handleReset = useCallback(() => {
    setInEditMode(null);
  }, []);

  useEffect(() => {
    getUsers(
      1,
      filterEmail,
      filterRole,
      filterEntity,
      filterStatus,
      filterManager
    );
  }, [
    getUsers,
    filterEmail,
    filterRole,
    filterEntity,
    filterStatus,
    filterManager,
  ]);

  const toggleShowWebsites = (userId) => {
    let userWebs = new Set(showWebsites);
    if (userWebs.has(userId)) {
      userWebs.delete(userId);
    } else {
      userWebs.add(userId);
    }
    setShowWebsites(userWebs);
  };

  const handleChangeUserStatus = (userId, isActivated) => {
    let status = isActivated;

    const issetUser = unActiveUsers.findIndex(
      (item) => item?.userId === userId
    );

    if (unActiveUsers[issetUser]) {
      unActiveUsers[issetUser].isActive = !unActiveUsers[issetUser].isActive;
      setInactiveUsers([...unActiveUsers]);
      status = !!unActiveUsers[issetUser].isActive;
    } else {
      const unActiveUser = {
        userId: userId,
        isActive: isActivated,
      };
      setInactiveUsers([...unActiveUsers, unActiveUser]);
    }
    updateUser(userId, { is_activated: status });
  };

  const handleChangeUserInForum = (userId, inForum) => {
    let status = inForum;
    const issetInNotInForumUser = notInForumUsers.findIndex(
      (item) => item?.userId === userId
    );

    if (notInForumUsers[issetInNotInForumUser]) {
      notInForumUsers[issetInNotInForumUser].inForum =
        !notInForumUsers[issetInNotInForumUser].inForum;
      setNotInForumUsers([...notInForumUsers]);
      status = !!notInForumUsers[issetInNotInForumUser].inForum;
    } else {
      const notInForumUser = {
        userId: userId,
        inForum: inForum,
      };
      setNotInForumUsers([...notInForumUsers, notInForumUser]);
    }
    updateUser(userId, { in_forum: status });
  };

  const checkUserStatus = (userId, isActive) => {
    let status = !!isActive;

    const userInUnActive = unActiveUsers.findIndex(
      (item) => item?.userId === userId
    );

    if (unActiveUsers[userInUnActive]) {
      status = unActiveUsers[userInUnActive].isActive;
    }
    return status;
  };

  const checkUserInForum = (userId, inForum) => {
    let status = !!inForum;

    const issetInNotInForumUser = notInForumUsers.findIndex(
      (item) => item?.userId === userId
    );

    if (notInForumUsers[issetInNotInForumUser]) {
      status = notInForumUsers[issetInNotInForumUser].inForum;
    }
    return status;
  };

  const usersList = data.map((item) => {
    return (
      <tr key={item.id}>
        <td>
          <Link to={`/admin/users/${item.id}`}>{item.id}</Link>
        </td>
        <td>
          <Link to={`/admin/users/${item.id}`}>
            <span className="ml-2">{item.fullname}</span>
          </Link>
        </td>
        <td>
          <span className="ml-2">{item.role}</span>
        </td>
        <td>{item.email}</td>
        <td>
          {item.own_websites?.map((w, i) => {
            return (
              <span
                className={`dividerV ${
                  i > 2 && !showWebsites.has(item.id) ? "d-none" : ""
                }`}
                key={w.id}
              >
                <Link to={`/admin/websites/${w.id}`}>{w.url}</Link>
              </span>
            );
          })}
          {item.own_websites?.length > 3 && (
            <span
              className="more-websites"
              onClick={() => toggleShowWebsites(item.id)}
            >
              <i
                className={`fa fa-${
                  showWebsites.has(item.id) ? "minus" : "plus"
                }-circle mr-2`}
              ></i>
            </span>
          )}
        </td>
        <td>{item.manager?.fullname}</td>
        <td>{t(entities[item.entity])}</td>
        <td>{item.created_at}</td>
        <td>{item.updated_at}</td>
        <td>
          <div className="custom-control custom-switch">
            <input
              checked={checkUserStatus(item.id, item.activated)}
              onChange={() =>
                handleChangeUserStatus(item.id, !!!item.activated)
              }
              type="checkbox"
              className="custom-control-input"
              id={`activate-${item.id}`}
            />
            <label
              className="custom-control-label"
              htmlFor={`activate-${item.id}`}
            ></label>
          </div>
        </td>
        <td>
          <div className="custom-control custom-switch">
            <input
              checked={checkUserInForum(item.id, item.in_forum)}
              onChange={() =>
                handleChangeUserInForum(item.id, !!!item.in_forum)
              }
              type="checkbox"
              className="custom-control-input"
              id={`switch-${item.id}`}
            />
            <label
              className="custom-control-label"
              htmlFor={`switch-${item.id}`}
            ></label>
          </div>
        </td>
        <td>
          <div className="dropdown">
            <AppButton theme="" onClick={(e) => handleEditUser(e, item.id)}>
              <i className="fas fa-ellipsis-v"></i>
            </AppButton>

            <div
              className={`dropdown-menu dropdown-menu-right ${
                inEditMode === item.id ? "show" : ""
              }`}
              aria-labelledby="dropdownMenuButton"
            >
              <Link className="dropdown-item" to={`/admin/users/${item.id}`}>
                <i className="fas fa-edit mr-2"></i>
                {t("common.edit")}
              </Link>
              <AppButton
                theme=""
                className="dropdown-item"
                onClick={(e) => handleDeleteUser(e, item)}
              >
                <i className="fas fa-trash-alt mr-2"></i>
                {t("common.delete")}
              </AppButton>
              <button className="dropdown-item">
                <i className="fas fa-file-contract mr-2"></i>
                {t("users.label.sendReport")}
              </button>
            </div>
          </div>
        </td>
      </tr>
    );
  });

  let mobile = width < 528;
  let caret = width < 1168 ? "caretDown" : "caretRight";

  let template = isLoading ? (
    <PageLoading />
  ) : (
    <>
      <div className="card" onClick={handleReset}>
        <div className="card-body table-responsive p-0">
          <table className="table table-striped table-valign-middle table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>{t("common.userName")}</th>
                <th>{t("common.role")}</th>
                <th>{t("common.email")}</th>
                <th>{t("common.websites")}</th>
                <th>{t("common.manager")}</th>
                <th>{t("common.entity")}</th>
                <th>{t("common.created_at")}</th>
                <th>{t("common.updated_at")}</th>
                <th>{t("common.status")}</th>
                <th>{t("common.inForum")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{usersList}</tbody>
          </table>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t("menuSidebar.label.users")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("menuSidebar.label.users")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="website-list-filter">
          <div className="row">
            <div
              className={`col-12 d-flex ${
                mobile ? "direction-column" : "direction-row"
              }`}
            >
              <AppButton
                icon={filterShow ? caret : "filter"}
                theme="default"
                className="filterButton"
                onClick={() => setFilterShow(!filterShow)}
              >
                {t("websites.button.filter")}
              </AppButton>
              {filterShow && (
                <ul
                  className={`other-date-options list-unstyled d-flex mb-0 direction-`}
                >
                  <li>
                    <div className="input-group mb-3">
                      <input
                        value={filterEmail}
                        className="form-control"
                        placeholder={t("common.email")}
                        onChange={(e) => setFilterEmail(e.target.value)}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="input-group mb-3">
                      <select
                        className="custom-select"
                        id="roleGroupSelect"
                        onChange={(e) => setFilterRole(e.target.value)}
                        value={filterRole}
                      >
                        <option value="">{`${t("common.choose")} ${t(
                          "common.role"
                        )}`}</option>
                        {Object.keys(userRoles)
                          .filter((item) => item !== "1")
                          .map((role, i) => {
                            return (
                              <option key={role} value={role}>
                                {t(userRoles[role])}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </li>
                  <li>
                    <div className="input-group mb-3">
                      <select
                        className="custom-select"
                        id="entityGroupSelect"
                        onChange={(e) => setFilterEntity(e.target.value)}
                        value={filterEntity}
                      >
                        <option value="">{`${t("common.choose")} ${t(
                          "common.entity"
                        )}`}</option>
                        {Object.keys(entities).map((entity) => {
                          return (
                            <option key={entity} value={entity}>
                              {t(entities[entity])}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </li>
                  <li>
                    <div className="input-group mb-3">
                      <select
                        className="custom-select"
                        id="entityGroupSelect"
                        onChange={(e) => setFilterStatus(e.target.value)}
                        value={filterStatus}
                      >
                        <option value="">{`${t("common.choose")} ${t(
                          "common.status"
                        )}`}</option>
                        <option value="1">{t("common.active")}</option>
                        <option value="0">{t("common.inactive")}</option>
                      </select>
                    </div>
                  </li>
                  <li>
                    <ManagerList
                      className="custom-select"
                      onChange={setFilterManager}
                    />
                  </li>
                  <li>
                    <div>
                      <AppButton
                        icon="filter"
                        theme="primary"
                        onClick={() =>
                          getUsers(
                            1,
                            filterEmail,
                            filterRole,
                            filterEntity,
                            filterStatus,
                            filterManager
                          )
                        }
                      >
                        {t("websites.button.filter")}
                      </AppButton>
                    </div>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        {template}
        {total > per_page && (
          <Pagination
            activePage={current_page || 0}
            itemsCountPerPage={per_page || 0}
            totalItemsCount={total || 0}
            onChange={(pageNumber) => {
              getUsers(
                pageNumber,
                filterEmail,
                filterRole,
                filterEntity,
                filterStatus,
                filterManager
              );
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText={t("common.firstPage")}
            lastPageText={t("common.lastPage")}
          />
        )}
      </div>
      {reconfirmModal.show && (
        <ReconfirmModal
          title={reconfirmModal.title}
          show={reconfirmModal.show}
          onHide={reconfirmModal.onHide}
          onSubmit={reconfirmModal.onSubmit}
          theme={reconfirmModal.theme}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { data, current_page, per_page, total, isLoading } = state.adminUsers;
  return {
    data,
    current_page,
    per_page,
    total,
    isLoading,
    width: state.theme.width,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (...rest) => dispatch(AdminUsersAction(...rest)),
    updateUser: (id, data) => dispatch(UserUpdateAction(id, data)),
    deleteUser: (id) => dispatch(AdminDeleteUsersAction(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
