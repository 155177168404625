import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { SetQuestionAction } from "../../../store/actions/admin/questions";
import Yup from "../../../helpers/yup";
import AppButton from "../../../components/button/Button";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";

const AdminAddQuestions = ({ setQuestion }) => {
  const { t } = useTranslation();
  const [answerType, setAnswerType] = useState(false);

  const initialValues = {
    questionEn: "",
    questionRu: "",
    type: "text",
    status: true,
    answers: [{ answerEn: "", answerRu: "" }],
  };

  const validationSchema = Yup.object().shape({
    questionEn: Yup.string().required(t("questions.validation.questionEn")),
    type: Yup.mixed().oneOf(["text", "radio", "checkbox"]),
    status: Yup.bool(),
    answers: Yup.mixed().when("type", {
      is: (val) => val === "radio" || val === "checkbox",
      then: Yup.array().of(
        Yup.object().shape({
          answerEn: Yup.string().required(t("questions.validation.answerEn")),
        })
      ),
      otherwise: Yup.mixed().nullable().notRequired(),
    }),
  });

  const handleAddAnswer = (values, setValues) => {
    const answers = [...values.answers];
    answers.push({ answerEn: "", answerRu: "" });
    setValues({ ...values, answers });
  };

  const handleDeleteAnswer = (index, values, setValues) => {
    const answers = [...values.answers];
    answers.splice(index, 1);
    setValues({ ...values, answers });
  };

  const handleSubmit = (data) => {
    if (data.type === "text") {
      delete data.answers;
    }
    setQuestion(data);
  };

  return (
    <div className="container-fluid">
      <div className="card m-3">
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data) => handleSubmit(data)}
          >
            {({ errors, values, touched, setValues }) => {
              return (
                <Form>
                  <div className="list-group list-group-flush">
                    <div className="col-xl-6 col-12 pl-0 pb-2">
                      <label>{t("questions.questionEn")}</label>
                      <Field
                        name={`questionEn`}
                        as="textarea"
                        placeholder={t("questions.questionEn")}
                        className={
                          "form-control" +
                          (errors.questionEn && touched.questionEn
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name={`questionEn`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="col-xl-6 col-12 pl-0 pb-2">
                      <label>{t("questions.status")}</label>
                      <Field
                        name={`status`}
                        type="checkbox"
                        placeholder={t("questions.status")}
                        className={
                          "form-check" +
                          (errors.status && touched.status ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name={`status`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-xl-6 col-12 pl-0 pb-2">
                      <label htmlFor="questionEn" className="labels">
                        {t("questions.typeOfAnswer")}
                      </label>
                      <Field name={`type`}>
                        {({ field, form }) => {
                          return (
                            <select
                              {...field}
                              onChange={(e) => {
                                form.setFieldValue(field.name, e.target.value);
                                e.target.value !== "text"
                                  ? setAnswerType(true)
                                  : setAnswerType(false);
                              }}
                              type="text"
                              className={
                                "form-control" +
                                (errors.type && touched.type
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value="text">
                                {t("questions.custom")}
                              </option>
                              <option value="radio">
                                {t("questions.single")}
                              </option>
                              <option value="checkbox">
                                {t("questions.multi")}
                              </option>
                            </select>
                          );
                        }}
                      </Field>
                      <ErrorMessage
                        name={`type`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {answerType && (
                      <div>
                        <FieldArray name="questions">
                          {() =>
                            values.answers.map((answer, i) => {
                              const answersErrors =
                                errors.answers?.length && errors.answers[i]
                                  ? errors.answers[i]
                                  : {};
                              const answersTouched =
                                touched.answers?.length && touched.answers[i]
                                  ? touched.answers[i]
                                  : {};
                              return (
                                <div key={i} className={`row`}>
                                  <div className="col-xl-12">
                                    <div className="col-xl-6 col-12 pl-0 pb-2">
                                      <label>
                                        {t("questions.answerEn")} {i + 1}
                                      </label>
                                      <Field
                                        name={`answers.${i}.answerEn`}
                                        as="textarea"
                                        placeholder={t("questions.answerEn")}
                                        className={
                                          "form-control" +
                                          (answersErrors.answerEn &&
                                          answersTouched.answerEn
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name={`answers.${i}.answerEn`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>
                                  {i === values.answers.length - 1 && (
                                    <div className="col-md-2 col-sm-12 form-group d-flex align-items-center pt-2">
                                      <AppButton
                                        disabled={i === 0}
                                        icon="close"
                                        theme="danger"
                                        onClick={() =>
                                          handleDeleteAnswer(
                                            i,
                                            values,
                                            setValues
                                          )
                                        }
                                      >
                                        {t("questions.delete")}
                                      </AppButton>
                                    </div>
                                  )}
                                </div>
                              );
                            })
                          }
                        </FieldArray>
                        <div className="form-group">
                          <div className="col p-0">
                            <AppButton
                              onClick={() => handleAddAnswer(values, setValues)}
                            >
                              <i className="bx bx-plus"></i>
                              {t("questions.add")}
                            </AppButton>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-group text-right">
                      <div className="col p-0">
                        <AppButton
                          type="submit"
                          theme="primary"
                          disabled={Object.keys(errors).length}
                        >
                          {t("questions.send")}
                        </AppButton>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setQuestion: (data) => dispatch(SetQuestionAction(data)),
});
export default connect(null, mapDispatchToProps)(AdminAddQuestions);
