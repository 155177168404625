import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { history } from "../../helpers";

const Referral = () => {
  useEffect(() => {
    if (!Cookies.get("referral")) {
      let ref_str = history.location.search;
      if (ref_str.includes("?dccl=")) {
        ref_str = ref_str.split("?dccl=")[1];
        Cookies.set("referral", ref_str, { expires: 30 });
      }
    }
    history.push("/register");
  }, []);
  return <></>;
};

export default Referral;
