import { toast } from "react-toastify";
import { http } from "../../../helpers";
import actionTypes from "../../action-types";
import i18n from "i18next";

export const AdminSourcesAction = (pageNumber) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_SOURCES_LOADING, payload: true });
    try {
      const params = {
        page: pageNumber ? pageNumber : 1,
      };
      const response = await http.get("/sources", { params });
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_ADMIN_SOURCES, payload: data });
      dispatch({ type: actionTypes.ADMIN_SOURCES_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.ADMIN_SOURCES_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminSourcesAddAction = (data) => {
  return async () => {
    try {
      await http.post("/sources", data);
      toast.success(`${i18n.t("sources.added")}`);
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminSourcesRefresh = (id, zone, period) => {
  return async () => {
    try {
      await http.post(`/sources/${id}/load`, { zone, period });
      zone
        ? toast.success(`${i18n.t("sources.zoneRefreshed")}`)
        : toast.success(`${i18n.t("sources.refreshed")}`);
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminCurrencyRefresh = (date) => {
  return async () => {
    try {
      await http.post(`/currency/refresh`, { date });
      toast.success(`${i18n.t("sources.currencyRefreshed")}`);
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminPrebidRefresh = () => {
  return async () => {
    try {
      await http.post(`/update-prebid-js`);
      toast.success(`${i18n.t("sources.prebidRefreshed")}`);
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminSourceSingleAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_SINGLE_SOURCE_LOADING, payload: true });
    try {
      const response = await http.get(`/sources/${id}`);
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_SINGLE_ADMIN_SOURCE, payload: data });
      dispatch({
        type: actionTypes.ADMIN_SINGLE_SOURCE_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: actionTypes.ADMIN_SINGLE_SOURCE_LOADING,
        payload: false,
      });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminUpdateSourceAction = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_SINGLE_SOURCE_LOADING, loading: true });
    try {
      await http.put(`/sources/${id}`, { ...data });
      dispatch({
        type: actionTypes.ADMIN_SINGLE_SOURCE_LOADING,
        loading: false,
      });
      toast.success(i18n.t("sources.sourceUpdated"));
    } catch (error) {
      dispatch({
        type: actionTypes.ADMIN_SINGLE_SOURCE_LOADING,
        loading: false,
      });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};
