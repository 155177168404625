import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { useTranslation, getI18n, Trans } from "react-i18next";
import * as Yup from "yup";
import Button from "../../components/button/Button";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import logo from "../../assets/img/login-logo.png";
import styles from "./register.module.css";
import registerWithJWT from "../../store/actions/auth/registerActions";
import { sortedCountries } from "../../helpers/users";
import {
  StrengthChecker,
  generateRandomString,
  history,
  printFormError,
} from "../../helpers";

const Register = (props) => {
  const { isAuthLoading, message, onUserRegister } = props;

  useEffect(() => {
    let mglink_str = history.location.search;
    if (mglink_str.includes("?mgref=")) {
      mglink_str = mglink_str.split("?mgref=")[1];
      Cookies.set("mglink", mglink_str, { expires: 90 });
    }
  }, []);

  const [t] = useTranslation();
  const [color, setColor] = useState("red");
  const [passwordLevelText, setPasswordLevelText] = useState("");
  const [randomPassword, setRandomPassword] = useState("");

  useEffect(() => {
    message.type && toast[message.type](`🦄 ${t(message.text)}`);
  }, [message, t]);

  const handleStrengthChecker = (password) => {
    const strengthLevel = StrengthChecker(password);
    const color =
      strengthLevel === "strong"
        ? "green"
        : strengthLevel === "medium"
        ? "mediumaquamarine"
        : "red";
    setColor(color);
    setPasswordLevelText(strengthLevel);
  };

  const handleGenerateRandomPassword = () => {
    const randomPassword = generateRandomString();
    setRandomPassword(randomPassword);
    formik.setFieldValue("password", randomPassword);
    handleStrengthChecker(randomPassword);
  };

  const countryNames = sortedCountries.map((country) => country.full_name);

  const phoneRegExp = /^\+?\d{7,16}$/;
  const formik = useFormik({
    initialValues: {
      email: "",
      fullName: "",
      phoneNumber: "",
      country: "",
      password: "",
      passwordRetype: "",
      terms: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("register.validation.email"))
        .required(t("register.validation.required")),
      fullName: Yup.string().required(t("register.validation.required")),
      phoneNumber: Yup.string().matches(
        phoneRegExp,
        "Phone number is not valid"
      ),
      country: Yup.string()
        .oneOf(countryNames, "Invalid country")
        .required(t("register.validation.required")),
      password: Yup.string()
        .min(8, t("register.validation.passMin"))
        .max(30, t("register.validation.passMax"))
        .required(t("register.validation.required")),
      passwordRetype: Yup.string()
        .min(8, t("register.validation.passMin"))
        .max(30, t("register.validation.passMax"))
        .required(t("register.validation.required"))
        .when("password", {
          is: (val) => !!(val && val.length),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("register.validation.passRetype")
          ),
        }),
      terms: Yup.bool().oneOf([true], t("register.validation.required")),
    }),
    onSubmit: (values) => {
      onUserRegister({
        referral: Cookies.get("referral") ? Cookies.get("referral") : "",
        mglink: Cookies.get("mglink") ? Cookies.get("mglink") : "",
        ...values,
      });
    },
  });

  document.getElementById("root").classList = "hold-transition register-page";
  return (
    <div className="register-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
            <img src={logo} alt="Logo" className="w-100" />
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">{t("register.registerNew")}</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <div className="input-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder={t("register.placeholder.email")}
                  {...formik.getFieldProps("email")}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              {printFormError(formik, "email")}
            </div>

            <div className="mb-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("register.placeholder.fullName")}
                  {...formik.getFieldProps("fullName")}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user" />
                  </div>
                </div>
              </div>
              {printFormError(formik, "fullName")}
            </div>

            <div className="mb-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("register.placeholder.phoneNumber")}
                  {...formik.getFieldProps("phoneNumber")}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-phone" />
                  </div>
                </div>
              </div>
              {printFormError(formik, "phoneNumber")}
            </div>
            {/* country */}
            <div className="mb-3">
              <div className="input-group">
                <select
                  name="country"
                  className="form-control"
                  {...formik.getFieldProps("country")}
                >
                  <option disabled selected value="">
                    {t("register.placeholder.country")}
                  </option>
                  {sortedCountries.map((country) => {
                    return (
                      <option key={country.name} value={country.full_name}>
                        {country.full_name}
                      </option>
                    );
                  })}
                </select>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-globe" />
                  </div>
                </div>
              </div>
              {printFormError(formik, "country")}
            </div>

            <div className="mb-3">
              <div className="input-group">
                {!!passwordLevelText.length && (
                  <span
                    className={"strength-checker checker-bg-light"}
                    style={{ color: color }}
                  >
                    {t(`password.${passwordLevelText}`)}
                  </span>
                )}
                <input
                  type={randomPassword.length ? "text" : "password"}
                  className="form-control"
                  placeholder={t("register.placeholder.password")}
                  style={{
                    border: passwordLevelText.length
                      ? `1px solid ${color}`
                      : "",
                  }}
                  {...formik.getFieldProps("password")}
                  onChange={(e) => {
                    handleStrengthChecker(e.target.value);
                    formik.setFieldValue("password", e.target.value);
                  }}
                />
                <i
                  className={`fa fa-key ${styles.password_generator_icon}`}
                  title={t("password.generate")}
                  onClick={() => handleGenerateRandomPassword()}
                ></i>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              {printFormError(formik, "password")}
            </div>

            <div className="mb-3">
              <div className="input-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder={t("register.placeholder.passwordRetype")}
                  {...formik.getFieldProps("passwordRetype")}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>

              {printFormError(formik, "passwordRetype")}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="icheck-primary">
                  <input
                    type="checkbox"
                    id="agreeTerms"
                    {...formik.getFieldProps("terms")}
                  />

                  <label htmlFor="agreeTerms">
                    <Trans i18nKey="register.termsCondition">
                      I accept{" "}
                      <Link
                        target="_blank"
                        to={{
                          pathname: `http://digitalcaramel.com/${
                            getI18n().language
                          }/public-offer/`,
                        }}
                      >
                        Public Offer Agreement
                      </Link>
                    </Trans>
                  </label>
                  {printFormError(formik, "terms")}
                  <div className="lh-1">
                    <small>
                      <Trans i18nKey="register.termsText">
                        By clicking on "Registration" button you agree with user
                        <Link
                          target="_blank"
                          to={{
                            pathname: `http://digitalcaramel.com/${
                              getI18n().language
                            }/public-offer/`,
                          }}
                        >
                          agreement conditions
                        </Link>
                        and confirm that you studied and agree with our
                        <Link
                          target="_blank"
                          to={{
                            pathname: `http://digitalcaramel.com/${
                              getI18n().language
                            }/privacy-policy/`,
                          }}
                        >
                          Privacy Policy
                        </Link>{" "}
                        .
                      </Trans>
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Button type="submit" block isLoading={isAuthLoading}>
                  {t("register.label")}
                </Button>
              </div>
            </div>
          </form>

          <Link to="/login" className="text-center">
            {t("register.alreadyHave")}
          </Link>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const { isAuthLoading, message } = state.auth;
  return {
    isAuthLoading,
    message,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onUserRegister: (values) => dispatch(registerWithJWT(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
