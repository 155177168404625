import actionTypes from "../../action-types";

const initialState = {
  current_page: 1,
  data: [],
  first_page_url: "",
  from: null,
  last_page: null,
  last_page_url: "",
  next_page_url: "",
  path: "",
  per_page: null,
  prev_page_url: null,
  to: null,
  total: null,
  isLoading: false,
};

const adminUsersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_ADMIN_USERS:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.ADMIN_USERS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case actionTypes.ADMIN_DELETE_USER:
      const id = payload;
      const users = state.data.filter((user) => user.id !== id);
      return {
        ...state,
        data: users,
      };
    default:
      return {
        ...state,
      };
  }
};
export default adminUsersReducer;
