import actionTypes from "../../action-types";
import { http } from "../../../helpers";
import i18n from "i18next";
import { toast } from "react-toastify";

const forgotPass = (email) => (dispatch) => {
  dispatch({ type: actionTypes.AUTH_LOADING, loading: true });
  http
    .post("/register/forgot-pass", {
      email: email,
    })
    .then((response) => {
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
      toast.success(`${i18n.t("recover.requestSend")}`);
    })
    .catch((err) => {
      toast.error(`${i18n.t("error.somethingWrong")}`);
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
    });
};

export default forgotPass;
