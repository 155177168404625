import { toast } from "react-toastify";
import {
  http,
  cpmArrays,
  showAmountOfNumber,
  sourceTitles,
  sumArrays,
  isFirstDayOfMonth,
  impressionFormat,
  calculateCPM,
} from "../../../helpers";
import actionTypes from "../../action-types";
import i18n from "i18next";

export const WebsiteReportAction = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.REPORTS_LOADING, payload: true });
    try {
      const params = {
        period: isFirstDayOfMonth() ? "yesterday" : "month",
      };
      const response = await http.get("/dashboard", { params });
      // TODO move to reducer
      let userHasBonus = false;
      const reports = response.data;
      if (reports.total_count !== 0) {
        let keys = Object.keys(reports.data.days);
        const data = {
          chartMultisite: {
            series: [],
            categories: new Set(),
          },
          sparkLineSum: {
            data: [],
            labels: keys,
          },
          sparkLineImps: {
            data: [],
            labels: keys,
          },
          sparkLineCpm: {
            data: [],
            labels: keys,
          },
          total: reports.total,
          totalSumWithBonus: reports.totalSumWithBonus,
          totalImps: reports.totalImps,
          totalCpm: reports.totalCpm,
          yesterday: { ...reports.data.days[keys[keys.length - 1]] },
        };
        const websites = new Set();
        for (let id in reports.data.list) {
          let website = reports.data.list[id];
          if (website.sum_bonus > 0) {
            userHasBonus = true;
          }
          websites.add(website.cname);
          let _line = {
            id: website.id,
            name: website.cname,
            type: "area",
            stack: website.cname,
            sum_usd: website.sum_usd,
            sum_bonus: showAmountOfNumber(website.sum_bonus),
            cpm_usd: website.cpm_usd,
            impressions_usd: website.impressions_usd,
            sum_usd_total: showAmountOfNumber(website.total_sum_with_bonus),
            data: [],
            show: false,
          };
          if (website.reports) {
            for (let i = 0; i < website.reports.length; i++) {
              _line.data.push(website.reports[i].value);
              data.chartMultisite.categories.add(website.reports[i].date);
            }
          }
          data.chartMultisite.series.push(_line);
        }

        for (let date in reports.data.days) {
          data.sparkLineSum.data.push(reports.data.days[date].sum);
          data.sparkLineImps.data.push(reports.data.days[date].impressions);
          data.sparkLineCpm.data.push(reports.data.days[date].cpm);
        }
        dispatch({ type: actionTypes.DASH_REPORT, payload: data });
      }
      dispatch({ type: actionTypes.REPORTS_LOADING, payload: false });
      dispatch({ type: actionTypes.USER_BONUS_PERCENT, payload: userHasBonus });
    } catch (error) {
      console.error(error);
      dispatch({ type: actionTypes.REPORTS_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const WebsitesReports = (period) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.WEBSITES_REPORTS_LOADING, payload: true });
    try {
      const params = {
        period: period ? period : "month",
      };
      const response = await http.get("/websites-reports", { params });
      const reports = response.data;
      // TODO move to reducer
      let userHasBonus = false;
      const data = {
        chartMultisite: {
          series: [],
          categories: new Set(),
        },
        total: {
          impressions: impressionFormat(reports?.totalImpressions),
          sum: showAmountOfNumber(reports?.totalSum),
          bonus: showAmountOfNumber(reports?.totalBonus),
          cpm: calculateCPM(reports?.totalImpressions, reports?.totalSum),
        },
      };
      const websites = new Set();
      for (let id in reports?.data?.list) {
        let website = reports?.data?.list[id];
        if (website.sum_bonus > 0) {
          userHasBonus = true;
        }
        websites.add(website.cname);
        let _line = {
          id: website.id,
          name: website.cname,
          type: "area",
          stack: website.cname,
          sum_usd: website.sum_usd,
          sum_bonus: showAmountOfNumber(website.sum_bonus),
          cpm_usd: website.cpm_usd,
          impressions_usd: website.impressions_usd,
          data: [],
          show: false,
        };
        if (website.reports) {
          for (let i = 0; i < website.reports.length; i++) {
            _line.data.push(website.reports[i].value);
            data.chartMultisite.categories.add(website.reports[i].date);
          }
        }
        data.chartMultisite.series.push(_line);
      }
      dispatch({ type: actionTypes.WEBSITES_REPORTS, payload: data });
      dispatch({ type: actionTypes.WEBSITES_REPORTS_LOADING, payload: false });
      dispatch({ type: actionTypes.USER_BONUS_PERCENT, payload: userHasBonus });
    } catch (error) {
      dispatch({ type: actionTypes.WEBSITES_REPORTS_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const WebsiteReport = (id, period) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.WEBSITE_REPORTS_LOADING, payload: true });
    try {
      const params = {
        website: id,
        period: period ? period : "month",
      };
      const response = await http.get("/reports", { params });
      const reports = response.data;
      const website = reports.website;
      //TODO move to reducer
      let userHasBonus = false;
      const data = {
        sum: reports.data.earnings,
        realSum: reports.data.real_earnings,
        cpm: reports.data.cpm,
        impressions: reports.data.impressions,
        chartMultiSources: {
          series: [],
          categories: new Set(),
        },
        chartMultiParams: {
          sumDays: [],
          bonusDays: [],
          impsDays: [],
          cpmDays: [],
        },
        sourceData: [],
      };
      let sourceData = [];
      let daysArr = [];
      let impsArr = [];
      let sumEarnings = 0;
      let sumBonus = 0;
      let sumCommission = 0;
      for (const src in reports.data.sources) {
        if (Object.hasOwnProperty.call(reports.data.sources, src)) {
          const sources = reports.data.sources[src];
          const sum_earnings =
            reports["sum_" + src] -
            reports["sum_" + src + "_commission"] +
            reports["sum_" + src + "_bonus"];
          const sum_commission =
            reports["sum_" + src + "_commission"] -
            reports["sum_" + src + "_bonus"];
          const newSrc = {
            type: src,
            title: sourceTitles[src],
            currency_id: reports["cur_" + src],
            sum: showAmountOfNumber(reports["sum_" + src]),
            real_sum: showAmountOfNumber(reports["real_sum_" + src]),
            sum_bonus: showAmountOfNumber(reports["sum_" + src + "_bonus"]),
            sum_usd_bonus: showAmountOfNumber(
              reports["sum_usd_" + src + "_bonus"]
            ),
            cpm: reports["cpm_" + src],
            sum_commission: showAmountOfNumber(sum_commission),
            sum_earnings: showAmountOfNumber(sum_earnings),
            impression: reports["impressions_" + src],
          };
          sumEarnings += sum_earnings;
          sumBonus += reports["sum_" + src + "_bonus"];
          sumCommission += sum_commission;
          sourceData.push(newSrc);
          let _line = {
            name: sourceTitles[src],
            source: src,
            type: "area",
            stack: sourceTitles[src],
            data: [],
            impression: [],
          };

          for (const key in sources) {
            if (Object.hasOwnProperty.call(sources, key)) {
              const source = sources[key];
              _line.data.push(source.value);
              _line.impression.push(source.impression);
              data.chartMultiSources.categories.add(source.date);
            }
          }
          daysArr.push(_line.data);
          impsArr.push(_line.impression);
          data.chartMultiSources.series.push(_line);
        }
        if (reports["sum_" + src + "_bonus"] > 0) {
          userHasBonus = true;
        }
      }
      const sumDays = sumArrays(...daysArr);
      const impsDays = sumArrays(...impsArr);
      const cpmDays = cpmArrays(sumDays, impsDays);
      data.chartMultiParams.sumDays = sumDays;
      data.chartMultiParams.impsDays = impsDays;
      data.chartMultiParams.cpmDays = cpmDays;
      data.chartMultiParams.bonusDays = reports.data.bonuses;
      data.sourceData = sourceData;
      data.sumEarnings = showAmountOfNumber(sumEarnings);
      data.sumBonus = showAmountOfNumber(sumBonus);
      data.sumCommission = showAmountOfNumber(sumCommission);
      const payload = {
        data,
        website,
      };
      dispatch({ type: actionTypes.WEBSITE_REPORTS, payload });
      dispatch({ type: actionTypes.WEBSITE_REPORTS_LOADING, payload: false });
      dispatch({ type: actionTypes.USER_BONUS_PERCENT, payload: userHasBonus });
    } catch (error) {
      dispatch({ type: actionTypes.WEBSITE_REPORTS_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const ZonesReports = (id, period) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ZONES_REPORTS_LOADING, payload: true });
    try {
      const params = {
        website: id,
        period: period ? period : "month",
      };
      const response = await http.get("/reports/zones", { params });
      const reports = response.data;
      const website = reports.website;
      // TODO move to reducer
      const data = {
        list: [],
        chart: {
          series: [],
          sumDays: [],
          categories: new Set(),
        },
        total: reports.data.earnings,
        realTotal: reports.data.real_earnings,
      };
      let daysArr = [];
      for (let zone_id in reports.data.zones) {
        const zones = reports.data.zones[zone_id];
        let _line = {
          name: zones[0].zone_name,
          type: "area",
          zone: zone_id,
          stack: "",
          data: [],
          impression: [],
        };
        let newZone = {
          zone: zone_id,
          title: zones[0].zone_name,
          size: zones[0].zone_size,
          sum: reports.data["sum_" + zone_id],
          real_sum: reports.data["real_sum_" + zone_id],
        };

        data.list.push(newZone);
        for (const zone in zones) {
          if (Object.hasOwnProperty.call(zones, zone)) {
            const item = zones[zone];
            _line.data.push(item.sum);
            data.chart.categories.add(item.date);
          }
        }
        daysArr.push(_line.data);
        data.chart.series.push(_line);
      }
      data.chart.sumDays = sumArrays(...daysArr);
      const payload = {
        data,
        website,
      };
      dispatch({ type: actionTypes.SET_ZONES, payload });
      dispatch({ type: actionTypes.ZONES_REPORTS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.ZONES_REPORTS_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const SetWebsiteReportPeriod = (payload) => ({
  type: actionTypes.SET_WEBSITE_PERIOD,
  payload,
});
