import React, { useContext } from "react";
import { connect } from "react-redux";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import MenuSidebar from "./menu-sidebar/MenuSidebar";
import * as ActionTypes from "../../store/actions";
import { WebsiteReportAction } from "../../store/actions/reports";
import { ThemeContext } from "../../contexts/ThemeContext";
import Routes from "../../routes";
import { getUserPath, isMaintenance } from "../../helpers";
import Maintenance from "../maintenance";
import { useTranslation } from "react-i18next";

const Main = ({ user }) => {
  const theme = useContext(ThemeContext);
  const { darkMode, isMenuSidebarCollapsed, toggleMenuSidebar } = theme;
  const { t } = useTranslation();
  document.getElementById("root").classList.remove("register-page");
  document.getElementById("root").classList.remove("login-page");
  document.getElementById("root").classList.remove("hold-transition");

  document.getElementById("root").className += " sidebar-mini";

  if (isMenuSidebarCollapsed) {
    document.getElementById("root").classList.add("sidebar-collapse");
    document.getElementById("root").classList.remove("sidebar-open");
  } else {
    document.getElementById("root").classList.add("sidebar-open");
    document.getElementById("root").classList.remove("sidebar-collapse");
  }

  return (
    <>
      {isMaintenance() && <Maintenance />}
      {!isMaintenance() && (
        <div className={`wrapper ${darkMode ? "dark-mode" : "light-mode"}`}>
          <Header />
          <MenuSidebar />
          <div className="content-wrapper">
            <div className="pt-3" />
            <section className="content">
              <Routes redirectUrl={getUserPath(user.role_id)} />
            </section>
          </div>
          <Footer />
          <div
            id="sidebar-overlay"
            role="presentation"
            onClick={toggleMenuSidebar}
            onKeyDown={() => {}}
          ></div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.currentUser,
    appLoadingState: state.reports.isLoading,
    width: state.theme.width,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUserLoad: (user) =>
    dispatch({ type: ActionTypes.LOAD_USER, currentUser: user }),
  reportLoad: () => dispatch(WebsiteReportAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
