import actionTypes from "../../action-types/";
import { history } from "../../../helpers";
import { http } from "../../../helpers";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const registerWithJWT = (values) => (dispatch) => {
  dispatch({ type: actionTypes.AUTH_LOADING, loading: true });
  http
    .post("/register/create", {
      phone: values.phoneNumber,
      fullname: values.fullName,
      lang: values.lang,
      email: values.email,
      country: values.country,
      password: values.password,
      referral: values.referral,
      mglink: values.mglink,
    })
    .then((response) => {
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
      if (response.data.token) {
        Cookies.set("confirm-token", response.data.token, { expires: 1 });
        history.push("/confirm");
      }
    })
    .catch((err) => {
      // TODO change to something wrong
      if (err.response.data.messages) {
        toast.error(`${err.response?.data?.messages}`);
      }
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
    });
};

export default registerWithJWT;
