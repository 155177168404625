import React, { useCallback, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import AppButton from "../button/Button";
import ReconfirmModal from "../modals/reconfirm";
import {
  AdminCurrencyRefresh,
  AdminPrebidRefresh,
} from "../../store/actions/admin/sources";
import AppDateRangePicker from "../daterangepicker";
import { ThemeContext } from "../../contexts/ThemeContext";
import AppImportFile from "../importFile";

const Additional = ({ isActive, refreshCurrency, refreshPrebid }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const theme = useContext(ThemeContext);
  const { darkMode } = theme;

  const [reconfirmModal, setReconfirmModal] = useState({
    show: false,
    title: null,
    onHide: null,
    onSubmit: null,
    theme: "",
  });

  const handleCloseReconfirmModal = () => {
    setReconfirmModal({
      title: "",
      show: false,
      onSubmit: () => {},
      onHide: () => {},
    });
  };

  const handleUpdate = useCallback(
    (e) => {
      e.stopPropagation();
      setReconfirmModal({
        title: t("common.refresh"),
        show: true,
        onSubmit: () => {
          refreshCurrency(startDate);
          handleCloseReconfirmModal();
        },
        onHide: () => handleCloseReconfirmModal(),
        theme: "success",
      });
    },
    [startDate, refreshCurrency, t]
  );

  const handlePrebidUpdate = () => {
    refreshPrebid();
  };

  return (
    <div className={`tab-pane ${isActive ? "active" : ""}`}>
      <h4>{t("menuSidebar.label.currency")}</h4>
      <div className="row col-12">
        <AppDateRangePicker
          className=" col-md-3"
          handlePickerDate={setStartDate}
          singleDatePicker
        />

        <div className="col-md-6 ">
          <AppButton
            onClick={(e) => handleUpdate(e)}
            type="btn"
            className="btn btn-primary"
          >
            {t("common.refresh")}
          </AppButton>
        </div>
      </div>
      {reconfirmModal.show && (
        <ReconfirmModal
          title={reconfirmModal.title}
          show={reconfirmModal.show}
          onHide={reconfirmModal.onHide}
          onSubmit={reconfirmModal.onSubmit}
          theme={reconfirmModal.theme}
        />
      )}
      <div
        className={`row mt-4 pt-4 border-top ${
          darkMode ? "border-secondary" : ""
        }`}
      >
        <div className="col-xl-12">
          <h4>Import ads.txt</h4>
          <AppImportFile
            url="/import/ads-txt"
            fileNameRegex={/\.txt$/i}
            fileTypeError={t("import.message.typeError")}
          />
        </div>
      </div>

      <div
        className={`row mt-4 pt-4 border-top ${
          darkMode ? "border-secondary" : ""
        }`}
      >
        <div className="col-xl-12">
          <h4>Prebid</h4>
          <AppButton
            onClick={(e) => handlePrebidUpdate(e)}
            type="btn"
            className="btn btn-primary"
          >
            {t("common.refresh")}
          </AppButton>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    refreshCurrency: (startDate) => dispatch(AdminCurrencyRefresh(startDate)),
    refreshPrebid: () => dispatch(AdminPrebidRefresh()),
  };
};

export default connect(null, mapDispatchToProps)(Additional);
