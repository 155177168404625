import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  createRef,
} from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Image from "../../../components/image";
import PageLoading from "../../../components/page-loading/PageLoading";
import {
  AdminSourcesAction,
  AdminSourcesRefresh,
} from "../../../store/actions/admin/sources";
import AppButton from "../../../components/button/Button";
import ReconfirmModal from "../../../components/modals/reconfirm.js";
import { matchSources } from "../../../../src/helpers/sources";
import AppDateRangePicker from "../../../components/daterangepicker";

const AdminSources = ({
  getSources,
  data,
  current_page,
  per_page,
  total,
  isLoading,
  refreshSource,
}) => {
  const [inEditMode, setInEditMode] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const itemsRef = useRef(data.map((e) => createRef(e.id)));

  useEffect(() => {
    getSources();
  }, [getSources]);

  const [reconfirmModal, setReconfirmModal] = useState({
    show: false,
    title: null,
    onHide: null,
    onSubmit: null,
    theme: "",
  });

  const handleRefresh = useCallback(
    (sourceID, sourceTitle, zoneRefresh) => {
      setDropdownOpen(false);
      setReconfirmModal({
        title: t("sources.refreshing") + " " + sourceTitle,
        show: true,
        onSubmit: () => {
          refreshSource(sourceID, zoneRefresh, startDate);
          handleCloseReconfirmModal();
        },
        onHide: () => handleCloseReconfirmModal(),
        theme: "success",
      });
    },
    [startDate, refreshSource, t]
  );

  const handleCloseReconfirmModal = () => {
    setReconfirmModal({
      title: "",
      show: false,
      onSubmit: () => {},
      onHide: () => {},
    });
  };

  const toggleDropdown = (e, id) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
    setInEditMode(id);
  };

  const handleClickOutside = (event) => {
    if (itemsRef && itemsRef.current) {
      itemsRef.current.forEach((item, index) => {
        if (
          dropdownOpen &&
          inEditMode === index &&
          !item.contains(event.target)
        ) {
          setDropdownOpen(false);
        }
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  });

  const sourceList = data.map((source) => {
    return (
      <tr key={source.id}>
        <td>{source.id}</td>
        <td>
          <Image
            style={{ maxHeight: "20px" }}
            name={`${source.type}-logo.png`}
          />
        </td>
        <td>{source.title}</td>
        <td>{source.description}</td>
        <td>{source.created_at}</td>
        <td>
          <div
            ref={(el) => (itemsRef.current[source.id] = el)}
            className="dropdown"
          >
            <AppButton theme="" onClick={(e) => toggleDropdown(e, source.id)}>
              <i className="fas fa-ellipsis-v" style={{ color: "white" }}></i>
            </AppButton>
            <div
              className={`dropdown-menu dropdown-menu-right ${
                dropdownOpen && inEditMode === source.id ? "show" : ""
              }`}
              aria-labelledby="dropdownMenuButton"
            >
              <button
                className="dropdown-item"
                onClick={() => handleRefresh(source.id, source.title, false)}
              >
                <i className="fas fa-sync-alt pr-2"></i>
                {t("common.refresh")}
              </button>
              {matchSources.indexOf(source.type) > -1 && (
                <button
                  className="dropdown-item"
                  onClick={() => handleRefresh(source.id, source.title, true)}
                >
                  <i className="fas fa-sync-alt pr-2"></i>
                  {t("common.zoneRefresh")}
                </button>
              )}
              <Link
                className="dropdown-item"
                to={`/admin/sources/${source.id}`}
              >
                <i className="fas fa-edit pr-2"></i>
                {t("common.edit")}
              </Link>
            </div>
          </div>
        </td>
      </tr>
    );
  });

  let template = isLoading ? (
    <PageLoading />
  ) : (
    <>
      <AppDateRangePicker
        handlePickerDate={setStartDate}
        singleDatePicker
        className="col-12 col-md-3 offset-md-9"
      />
      <div className="card">
        <div className="card-body table-responsive p-0">
          <table className="table table-striped table-valign-middle">
            <thead>
              <tr>
                <th>ID</th>
                <th>{t("common.type")}</th>
                <th>{t("common.title")}</th>
                <th>{t("common.description")}</th>
                <th>{t("common.date")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{sourceList}</tbody>
          </table>
        </div>
      </div>
    </>
  );

  return (
    <div className="container-fluid">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t("menuSidebar.label.sources")}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/admin">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  {t("menuSidebar.label.sources")}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      {template}
      {total > per_page && (
        <Pagination
          activePage={current_page || 0}
          itemsCountPerPage={per_page || 0}
          totalItemsCount={total || 0}
          onChange={(pageNumber) => {
            getSources(pageNumber);
          }}
          pageRangeDisplayed={8}
          itemClass="page-item"
          linkClass="page-link"
          firstPageText={t("common.firstPage")}
          lastPageText={t("common.lastPage")}
        />
      )}
      {reconfirmModal.show && (
        <ReconfirmModal
          title={reconfirmModal.title}
          show={reconfirmModal.show}
          onHide={reconfirmModal.onHide}
          onSubmit={reconfirmModal.onSubmit}
          theme={reconfirmModal.theme}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { data, current_page, per_page, total, isLoading } = state.adminSources;
  return {
    data,
    current_page,
    per_page,
    total,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSources: (pageNumber) => dispatch(AdminSourcesAction(pageNumber)),
    refreshSource: (id, zone, startDate) =>
      dispatch(AdminSourcesRefresh(id, zone, startDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSources);
