import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { version } from "../../../../package.json";
import { ThemeContext } from "../../../contexts/ThemeContext";
import {
  ShowQuestionsModal,
  GetQuestionsAction,
  SetUserAnswerAction,
} from "../../../store/actions/users";
import { connect } from "react-redux";
import styles from "./index.module.css";
import AppButton from "../../../components/button/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { printFormError } from "../../../helpers";

const Footer = ({
  user,
  questions,
  showBlock,
  getQuestions,
  setUserAnswers,
  showQuestionsModal,
}) => {
  const [t, i18n] = useTranslation();
  const theme = useContext(ThemeContext);
  const { darkMode } = theme;
  const { role_id } = user;
  const lang = i18n.language;

  useEffect(() => {
    if (role_id > 2) {
      getQuestions();
    }
  }, [getQuestions, role_id]);

  const initialValues = {};
  const validationSchema = {};
  for (const question of questions) {
    const { id, type } = question;
    if (type === "text" || type === "radio") {
      initialValues[id] = "";
      validationSchema[id] = Yup.string().required(
        t("questions.validation.isRequired")
      );
    } else {
      initialValues[id] = [];
      validationSchema[id] = Yup.array()
        .min(1, t("questions.validation.select"))
        .required(t("questions.validation.isRequired"));
    }
  }
  const configFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: (values) => {
      const questionValuesWithKeys = {};
      for (const key in values) {
        const question = questions.find((q) => q.id === +key);
        if (question) {
          questionValuesWithKeys[key] = {
            [question.type]:
              question.type !== "checkbox" ? values[key] : [values[key]],
          };
        }
      }
      setUserAnswers(questionValuesWithKeys);
    },
  });
  const handleShowQuestionsModal = (data) => {
    showQuestionsModal(data);
  };
  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-block">
        <b>{t("footer.version")}</b>
        <b> </b>
        <span>{version}</span>
      </div>
      <strong>
        <span>Copyright © {DateTime.now().toFormat("y")} </span>
        <a
          href="https://digitalcaramel.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Digital Caramel
        </a>
        <span>.</span>
      </strong>
      <span> </span>
      <span>{t("footer.copyright")}</span>

      {showBlock && user.role_id > 2 && !!questions.length && (
        <div
          className={`${
            darkMode ? styles["dark-question-block"] : styles["question-block"]
          }`}
        >
          <div className="d-flex justify-content-end p-2 border-bottom">
            <AppButton
              icon="close"
              theme="danger"
              onClick={() => handleShowQuestionsModal(false)}
            ></AppButton>
          </div>
          <div className={`${styles["question-list"]}`}>
            <form name="questionForm" onSubmit={configFormik.handleSubmit}>
              {questions.map((question) => (
                <ul key={question.id}>
                  <li>
                    <span className="text-bold">
                      {question.text[lang] || ""}
                    </span>
                    {question.type !== "text" && question.answers && (
                      <ul>
                        {question.answers?.map((answer) => {
                          return (
                            <li key={answer.id}>
                              <div className="d-flex">
                                <input
                                  id={`${question.id}.${answer.id}`}
                                  name={question.id}
                                  value={answer.id}
                                  type={question.type}
                                  className="m-2"
                                  onChange={configFormik.handleChange}
                                />
                                <label
                                  className="mt-2"
                                  htmlFor={`${question.id}.${answer.id}`}
                                >
                                  {answer.text[lang] || ""}
                                </label>
                              </div>
                            </li>
                          );
                        })}
                        {printFormError(configFormik, question.id)}
                      </ul>
                    )}
                    {question.type === "text" && (
                      <>
                        <textarea
                          name={question.id}
                          className={styles.textarea}
                          {...configFormik.getFieldProps(question.id)}
                        />

                        {printFormError(configFormik, question.id)}
                      </>
                    )}
                  </li>
                </ul>
              ))}
              <div className="text-right">
                <AppButton type="submit" className="m-2">
                  {t("questions.send")}
                </AppButton>
              </div>
            </form>
          </div>
        </div>
      )}
    </footer>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
  questions: state.questions.list,
  showBlock: state.questions.showBlock,
});
const mapDispatchToProps = (dispatch) => ({
  showQuestionsModal: (payload) => dispatch(ShowQuestionsModal(payload)),
  getQuestions: () => dispatch(GetQuestionsAction()),
  setUserAnswers: (fields) => dispatch(SetUserAnswerAction(fields)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
