import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Button from "../../components/button/Button";
import { loginWithJWT } from "../../store/actions/auth/loginActions";
import logo from "../../assets/img/login-logo.png";
import styles from "./login.module.css";
import { printFormError } from "../../helpers";

const Login = (props) => {
  const { isAuthLoading, onUserLogin, location } = props;
  const gotoPath = location.state?.from?.pathname;

  const [t] = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("login.validation.email"))
        .required(t("login.validation.required")),
      password: Yup.string()
        .min(5, t("login.validation.passMin"))
        .max(30, t("login.validation.passMax"))
        .required(t("login.validation.required")),
    }),
    onSubmit: (values) => {
      if (gotoPath?.length > 1) {
        values.redirect = gotoPath;
      }
      onUserLogin(values);
    },
  });

  document.getElementById("root").classList = "hold-transition login-page";

  return (
    <>
      <div className="login-box">
        <div
          className={`card card-outline card-primary  ${styles.card_outline}`}
        >
          <div className="card-header text-center">
            <Link to="/" className="h1">
              <img src={logo} alt="Logo" className="w-100" />
            </Link>
          </div>
          <div className="card-body">
            <p className="login-box-msg">{t("login.label.signIn")}</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t("login.placeholder.email")}
                    {...formik.getFieldProps("email")}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                </div>
                {printFormError(formik, "email")}
              </div>
              <div className="mb-3">
                <div className="input-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder={t("login.placeholder.password")}
                    {...formik.getFieldProps("password")}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                {printFormError(formik, "password")}
              </div>

              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">
                      {t("login.label.rememberMe")}
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <Button type="submit" isLoading={isAuthLoading}>
                    {t("login.button.signIn.label")}
                  </Button>
                </div>
              </div>
            </form>

            <div className="row">
              <div className="col-7">
                <p className="mb-1">
                  <Link to="/forgot-password">
                    {t("login.label.forgotPass")}
                  </Link>
                </p>
                <p className="mb-0">
                  <Link to="/register" className="text-center">
                    {t("login.label.registerNew")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { isAuthLoading } = state.auth;
  return {
    isAuthLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUserLogin: (token) => dispatch(loginWithJWT(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
