import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import "./i18n";
import jwt from "jsonwebtoken";
import { history, http } from "./helpers";
import actionTypes from "./store/action-types/index";
const jwt_secret = process.env.REACT_APP_JWT_SECRET;

let token = localStorage.getItem("dc-token");
if (token) {
  jwt.verify(token, jwt_secret, (err, decoded) => {
    if (err) {
      token = null;
      store.dispatch({ type: "LOGOUT_USER" });
      history.push("/login");
    } else {
      const allowed_iss = [`${process.env.REACT_APP_API_URL}/auth/login`];
      if (!allowed_iss.includes(decoded.iss)) {
        token = null;
        store.dispatch({ type: actionTypes.LOGOUT_USER });
        history.push("/login");
      }
    }
  });
}

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};
if (token) {
  http.post("/auth/me").then((res) => {
    store.dispatch({ type: actionTypes.LOGIN_USER, currentUser: res.data });
    render();
  });
} else {
  render();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
