import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppButton from "../../../components/button/Button";
import WebsiteListFilter from "../../../components/filter/websiteListFilter";
import ReconfirmModal from "../../../components/modals/reconfirm";
import NoData from "../../../components/noData";
import PageLoading from "../../../components/page-loading/PageLoading";
import {
  AdminDeleteWebsite,
  AdminGetWebsitesAction,
} from "../../../store/actions/admin/websites";

const AdminWebsites = ({
  data,
  current_page,
  per_page,
  total,
  isLoading,
  getWebsites,
  match,
  deleteWebsite,
}) => {
  const pageNew = match.url === "/admin/websites/new";

  const [filterEmail, setFilterEmail] = useState("");
  const [filterWebsite, setFilterWebsite] = useState("");
  const [filterManager, setFilterManager] = useState(null);
  const [filterStatus, setFilterStatus] = useState(pageNew ? "pending" : "");
  const [reconfirmModal, setReconfirmModal] = useState({
    show: false,
    title: null,
    onHide: null,
    onSubmit: null,
    theme: "",
  });
  const { t } = useTranslation();

  const handleDeleteWebsite = useCallback(
    (id, url) => {
      setReconfirmModal({
        title: t("zone.deleting") + " " + url,
        show: true,
        onSubmit: () => {
          deleteWebsite(id);
          handleCloseReconfirmModal();
        },
        onHide: () => handleCloseReconfirmModal(),
        theme: "danger",
      });
    },
    [deleteWebsite, t]
  );

  const handleCloseReconfirmModal = () => {
    setReconfirmModal({
      title: "",
      show: false,
      onSubmit: () => {},
      onHide: () => {},
    });
  };

  useEffect(() => {
    getWebsites(null, filterEmail, filterWebsite, filterStatus, filterManager);
  }, [getWebsites, filterEmail, filterWebsite, filterStatus, filterManager]);

  const websitesList = data.map((website) => {
    return (
      <tr
        key={website.id}
        className={website.commission_perc === 0 ? "bg-danger" : ""}
      >
        <td>
          <Link to={`/admin/websites/${website.id}`}>{website.id}</Link>
        </td>
        <td>
          <span className="ml-2">
            <Link to={`/admin/websites/${website.id}`}>{website.url}</Link>
          </span>
        </td>
        <td>
          <Link
            className="btn-link"
            to={`/admin/users/${website.main_user?.id}`}
          >
            {website.main_user?.email}
          </Link>
        </td>
        <td>{website.manager}</td>
        <td>
          <AppButton
            theme="danger"
            onClick={() => handleDeleteWebsite(website.id, website.url)}
          >
            {" "}
            Delete
          </AppButton>
        </td>
      </tr>
    );
  });

  let template = isLoading ? (
    <PageLoading />
  ) : (
    <>
      {!!websitesList.length ? (
        <div className="card">
          <div className="card-body table-responsive p-0">
            <table className="table table-striped table-valign-middle table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{t("website.label.domain")}</th>
                  <th>{t("menuSidebar.label.users")}</th>
                  <th>{t("website.label.manager")}</th>
                  <th>{t("menuSidebar.label.settings")}</th>
                </tr>
              </thead>
              <tbody>{websitesList}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <NoData />
      )}
    </>
  );

  return (
    <div className="container-fluid">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>
                {t(
                  `menuSidebar.label.${pageNew ? "newWebsites" : "allWebsites"}`
                )}
              </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/admin">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  {t(
                    `menuSidebar.label.${
                      pageNew ? "newWebsites" : "allWebsites"
                    }`
                  )}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <WebsiteListFilter
        filterEmail={filterEmail}
        filterWebsite={filterWebsite}
        filterStatus={filterStatus}
        filterManager={filterManager}
        setFilterEmail={setFilterEmail}
        setFilterWebsite={setFilterWebsite}
        setFilterStatus={setFilterStatus}
        setFilterManager={setFilterManager}
        isLoading={isLoading}
      />
      {template}
      {total > per_page && (
        <Pagination
          activePage={current_page || 0}
          itemsCountPerPage={per_page || 0}
          totalItemsCount={total || 0}
          onChange={(pageNumber) => {
            getWebsites(
              pageNumber,
              filterEmail,
              filterWebsite,
              filterStatus,
              filterManager
            );
          }}
          pageRangeDisplayed={8}
          itemClass="page-item"
          linkClass="page-link"
          firstPageText={t("common.firstPage")}
          lastPageText={t("common.lastPage")}
        />
      )}
      {reconfirmModal.show && (
        <ReconfirmModal
          title={reconfirmModal.title}
          show={reconfirmModal.show}
          onHide={reconfirmModal.onHide}
          onSubmit={reconfirmModal.onSubmit}
          theme={reconfirmModal.theme}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { data, current_page, per_page, total, isLoading } =
    state.adminWebsites;
  return {
    data,
    current_page,
    per_page,
    total,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWebsites: (pageNumber, email, domain, status, managerId) =>
      dispatch(
        AdminGetWebsitesAction(pageNumber, email, domain, status, managerId)
      ),
    deleteWebsite: (id) => dispatch(AdminDeleteWebsite(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(AdminWebsites));
