import i18n from "i18next";
import { toast } from "react-toastify";
import { http } from "../../../helpers";
import actionTypes from "../../action-types";

export const AdminGetActiveWebsitesAction = (
  period,
  pageNumber,
  email,
  domain,
  status,
  managerId,
  order
) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADMIN_ACTIVE_WEBSITES_LOADING,
      payload: true,
    });
    try {
      const params = {
        page: pageNumber ? pageNumber : 1,
        period,
        email,
        domain,
        status,
        managerId,
        orderBy: order?.orderBy,
        ascing: order?.ascing ? "ASC" : "DESC",
      };
      const response = await http.get(`/admin/websites/active`, { params });
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_ADMIN_ACTIVE_WEBSITES, payload: data });
      dispatch({
        type: actionTypes.ADMIN_ACTIVE_WEBSITES_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: actionTypes.ADMIN_ACTIVE_WEBSITES_LOADING,
        payload: false,
      });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminGetWebsitesAction = (
  pageNumber,
  email,
  domain,
  status,
  managerId
) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_WEBSITES_LOADING, payload: true });
    try {
      const params = {
        page: pageNumber ? pageNumber : 1,
        email,
        domain,
        status,
        managerId,
      };
      const response = await http.get(`/admin/websites`, { params });
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_ADMIN_WEBSITES, payload: data });
      dispatch({
        type: actionTypes.ADMIN_WEBSITES_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: actionTypes.ADMIN_WEBSITES_LOADING,
        payload: false,
      });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminFetWebsiteReport = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_WEBSITES_LOADING, payload: true });
    try {
      const response = await http.get(`/websites/${id}/report`);
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_ADMIN_WEBSITES_REPORT, payload: data });
      dispatch({ type: actionTypes.ADMIN_WEBSITES_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.ADMIN_WEBSITES_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminDeleteWebsite = (id) => {
  return async (dispatch) => {
    try {
      await http.delete(`/websites/${id}`);
      dispatch({ type: actionTypes.ADMIN_DELETE_WEBSITE, payload: id });
      toast.success(i18n.t("website.deleted"));
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};
