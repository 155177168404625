import axios from "axios";
import { getI18n } from "react-i18next";
import actionTypes from "../store/action-types";
import store from "../store";
import { isMaintenance } from "./functions";
import { Redirect } from "react-router-dom";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
});

http.interceptors.request.use(function (config) {
  const isInstanceofFormData = config.data instanceof FormData;
  let token = localStorage.getItem("dc-token");
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
    "Content-Type": isInstanceofFormData
      ? "multipart/form-data"
      : "application/json",
  };

  if (!isInstanceofFormData) {
    config.data = {
      ...config.data,
      lang: getI18n().language,
    };
  }

  return config;
});

http.interceptors.response.use(
  (response) => {
    if (isMaintenance()) {
      return <Redirect to="/maintenance" />;
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch({ type: actionTypes.LOGOUT_USER });
      }
    }
    return Promise.reject(error);
  }
);
export { http };
